import { IS_ENV_DEV } from 'consts/env.consts'
import { pdfjs } from 'react-pdf'

export class PdfWorkerHelper {
    public async load() {
        try {
            const response = await fetch(
                new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()
            ).then((res) => res.blob())

            const blob = response.slice(0, response.size, 'application/javascript')

            pdfjs.GlobalWorkerOptions.workerSrc = URL.createObjectURL(blob)
        } catch (error) {
            if (IS_ENV_DEV) console.error(error)
        }
    }
}

export const pdfWorkerHelper = new PdfWorkerHelper()
