import dayjs from 'dayjs'
import { APP_LOCALIZATION } from 'localization'
import { formatDate } from 'mmc-react-shared'

export const formatDateWithExpiration = (expirationDate?: string | null) => {
    if (!expirationDate) return

    return `${APP_LOCALIZATION.DATE.EXPIRE_DATE} - ${formatDate(expirationDate)}`
}

export const formatDateWithYearOfChange = (expirationDate?: number | null) => {
    if (!expirationDate) return

    return `${APP_LOCALIZATION.DATE.YEAR_OF_CHANGE} - ${expirationDate}`
}

export const dayjsSortHelper = <T extends dayjs.ConfigType>(a?: T, b?: T) =>
    dayjs(a).isAfter(dayjs(b)) ? -1 : 1
