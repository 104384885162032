import React, { FC } from 'react'
import { Header } from '../Header'
import { PSYCHIATRISTIC_NARCOLOGIST_LOCALIZATION } from './PsychiatristNarcologist.localization'
import { Col, Row } from 'antd'
import { FieldView } from 'components/controls/FieldView'
import { useGetProfile } from 'hooks/useGetProfile'
import { formatDate } from 'mmc-react-shared'
import { getSeriesAndNumber } from 'utils/common.utils'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocuments/CitizenCardDocuments.consts'
import { DocumentView } from 'components/controls/DocumentView'

export const PsychiatristNarcologist: FC = React.memo(() => {
    const { profile } = useGetProfile()

    const document =
        profile?.medicalDocs?.find(
            ({ medicalDocumentType }) =>
                medicalDocumentType === 'PsychiatristNarcologistVerdict'
        ) || {}

    return (
        <>
            <Header title={PSYCHIATRISTIC_NARCOLOGIST_LOCALIZATION.TITLE} />

            <Row {...CITIZEN_DOC_GRID_ROW}>
                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={PSYCHIATRISTIC_NARCOLOGIST_LOCALIZATION.SERIES_AND_NUMBER}
                        value={getSeriesAndNumber(document)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={PSYCHIATRISTIC_NARCOLOGIST_LOCALIZATION.ISSUED_AT}
                        value={formatDate(document.issuedAt)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={PSYCHIATRISTIC_NARCOLOGIST_LOCALIZATION.EXPIRATION_DATE}
                        value={formatDate(document.expirationDate)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={PSYCHIATRISTIC_NARCOLOGIST_LOCALIZATION.ISSUER}
                        value={document.issuer}
                    />
                </Col>

                <Col xs={24}>
                    <DocumentView files={document.files} />
                </Col>
            </Row>
        </>
    )
})
