import { Gutter } from 'antd/es/grid/row'

export const GUTTER: [Gutter, Gutter] = [{ xs: 0, md: 24 }, 24]

export const APPEAL_ATTENTION_STATUSES = [
    'ServicesPending',
    'NeedChanges',
    'RejectedMvd',
    'Positive',
    'Negative',
    'ReadyForDelivery',
]
