import { StoreValue } from 'mmc-react-shared'
import {
    INTERCEPTER_RESPONSE_ERROR_EXCEPTIONS,
    INTERCEPTER_RESPONSE_SUCCESS_EXCEPTIONS,
} from './api.consts'
import { matchIntercepterExceptions, redirectToLoginPage } from './api.utils'
import { notification } from 'antd'
import { StatusCodes } from 'http-status-codes'
import { APP_LOCALIZATION } from 'localization'

export const interceptorsResponseResolve = (response: StoreValue) => {
    if (
        matchIntercepterExceptions(
            INTERCEPTER_RESPONSE_SUCCESS_EXCEPTIONS,
            response.config.url,
            response.config.method
        )
    )
        return response

    return response
}

export const interceptorsResponseReject = (error: StoreValue) => {
    if (
        matchIntercepterExceptions(
            INTERCEPTER_RESPONSE_ERROR_EXCEPTIONS,
            error.config.url,
            error.config.method
        )
    ) {
        return Promise.reject(error)
    }

    if (error.response) {
        switch (error.response.status) {
            case StatusCodes.UNAUTHORIZED:
                if (error.response.headers.location) {
                    redirectToLoginPage(error.response.headers.location)
                }

                break
            case StatusCodes.TOO_MANY_REQUESTS:
                notification.error({
                    message:
                        APP_LOCALIZATION.ERRORS.RESPONSE[StatusCodes.TOO_MANY_REQUESTS],
                })

                break
            case StatusCodes.NOT_FOUND:
            case StatusCodes.FORBIDDEN:
            case StatusCodes.INTERNAL_SERVER_ERROR:
            case StatusCodes.NOT_IMPLEMENTED:
            case StatusCodes.BAD_GATEWAY:
            case StatusCodes.SERVICE_UNAVAILABLE:
            case StatusCodes.BAD_REQUEST:
            case StatusCodes.GATEWAY_TIMEOUT:
            default:
                notification.error({ message: error.response.statusText })

                break
        }
    }

    return Promise.reject(error)
}
