import React, { FC } from 'react'
import { Header } from '../Header'
import { INN_DOCUMENT_LOCALIZATION } from './InnDocument.localization'
import { Col, Row } from 'antd'
import { FieldView } from 'components/controls/FieldView'
import { useGetProfile } from 'hooks/useGetProfile'
import { formatDate } from 'mmc-react-shared'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocuments/CitizenCardDocuments.consts'
import { DocumentView } from 'components/controls/DocumentView'

export const InnDocument: FC = React.memo(() => {
    const { profile } = useGetProfile()

    const document = profile?.innDocs?.[0] || {}

    return (
        <>
            <Header title={INN_DOCUMENT_LOCALIZATION.TITLE} />

            <Row {...CITIZEN_DOC_GRID_ROW}>
                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={INN_DOCUMENT_LOCALIZATION.NUMBER}
                        value={document.innNumber}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={INN_DOCUMENT_LOCALIZATION.ISSUER}
                        value={document.issuer}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={INN_DOCUMENT_LOCALIZATION.REGISTRATION_DATE}
                        value={formatDate(document.registrationDate)}
                    />
                </Col>

                <Col xs={24}>
                    <DocumentView files={document.files} />
                </Col>
            </Row>
        </>
    )
})
