import { Gutter } from 'antd/es/grid/row'

export const CITIZEN_DOC_GRID_ROW = {
    gutter: [24, 24] as [Gutter, Gutter],
    className: 'tablet:px-16',
}

export const CITIZEN_DOC_GRID_COL = {
    xs: 24,
    md: 6,
}
