import { Store, TagProps } from 'mmc-react-shared'

export const TAG_PRESETS: Store<TagProps['preset']> = {
    Positive: 'success',
    HandedApproval: 'success',
    ServicesPending: 'warning',
    NeedReview: 'warning',
    NeedChanges: 'warning',
    SentMvd: 'warning',
    ReadyForDelivery: 'warning',
    RejectedMvd: 'error',
    Negative: 'error',
    HandedDisapproval: 'error',
    Annul: 'error',
}
