export * from './actions.localization'
import { ACTIONS_LOCALIZATION } from './actions.localization'
import { DATE_LOCALIZATION } from './date.localization'
import {
    DMS_TYPES_LOCALIZATION,
    GENDER_LOCALIZATION,
    IDENTITY_DOCUMENT_TYPES_LOCALIZATION,
} from './dictionaries.localization'
import { ERRORS_RESPONSE_LOCALIZATION } from './errors.localization'
import { PLACEHOLDERS_LOCALIZATIONS } from './placeholders.localization'

export const APP_LOCALIZATION = {
    ACTIONS: ACTIONS_LOCALIZATION,
    DATE: DATE_LOCALIZATION,
    DICTIONARIES: {
        GENDER: GENDER_LOCALIZATION,
        IDENTITY_DOCUMENT_TYPES: IDENTITY_DOCUMENT_TYPES_LOCALIZATION,
        DMS_TYPES: DMS_TYPES_LOCALIZATION,
    },
    ERRORS: {
        RESPONSE: ERRORS_RESPONSE_LOCALIZATION,
    },
    PLACEHOLDERS: PLACEHOLDERS_LOCALIZATIONS,
}
