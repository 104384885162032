import { memo, useCallback, useMemo } from 'react'

import { notification } from 'antd'
import classNames from 'classnames'
import { Icon } from 'mmc-react-shared'

import { downloadFile, getFileUrl } from 'utils/file.utils'

import { PREVIEW_SIZES } from './DocumentPreview.consts'
import { DocumentPreviewProps } from './DocumentPreview.types'
import { DOCUMENT_PREVIEW_LOCALIZATION } from './DocumentPreview.localization'

import styles from './DocumentPreview.module.css'

export const DocumentPreview: React.FC<DocumentPreviewProps> = memo(
    ({ file, openFullscreen, fileIndex }) => {
        const [notificationApi, notificationContext] = notification.useNotification()

        const handleDocxClick = useCallback(() => {
            notificationApi.warning({
                message: DOCUMENT_PREVIEW_LOCALIZATION.WARNING_ONLY_DOWNLOAD,
            })

            downloadFile(file)
        }, [file, notificationApi])

        const handleShowFullscreen = useCallback(() => {
            openFullscreen(fileIndex)
        }, [fileIndex, openFullscreen])

        const previewVariants = useMemo(() => {
            const pdfPreviewId = file.previewId

            const previewSrc = getFileUrl(pdfPreviewId || file.id)

            switch (file.fileType) {
                case 'Jpeg':
                case 'Png':
                case 'Pdf':
                    return (
                        <img
                            {...PREVIEW_SIZES}
                            className={classNames(styles.preview, styles.bordered)}
                            src={previewSrc}
                            onClick={handleShowFullscreen}
                        />
                    )
                case 'Docx':
                    return (
                        <Icon
                            svgType="SvgIconFileDownload16X20"
                            iconContainerClassName={styles.bordered}
                            onClick={handleDocxClick}
                            iconContainer={PREVIEW_SIZES}
                        />
                    )
            }
        }, [file, handleDocxClick, handleShowFullscreen])

        return (
            <>
                {notificationContext}

                {previewVariants}
            </>
        )
    }
)
