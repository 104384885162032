import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useScrollTo(dataScrollAttrKey: string) {
    const [searchParams] = useSearchParams()

    const scrollToId = searchParams.get('scrollTo')

    useEffect(() => {
        const elem =
            scrollToId &&
            document.querySelector(`[data-${dataScrollAttrKey}="${scrollToId}"]`)

        if (elem) {
            elem.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        }

        return () => {
            document.body.scrollIntoView({
                block: 'start',
            })
        }
    }, [dataScrollAttrKey, scrollToId])
}
