export const CITIZEN_WORK_DATA_LOCALIZATION = {
    PATENT: 'Патент',
    WORK_STATUS: 'Статус',
    SERIES_NUMBER: 'Серия и номер',
    ISSUE_DATE: 'Дата выдачи',
    EXPIRES_DATE: 'Действителен до',
    ISSUED_BY: 'Кем выдан',
    LICENSE_TERRITORY: 'Территория действия',
    EMPLOYER: 'Работодатель',
    TYPE_EMPLOYER: 'Тип работодателя',
    OGRN: 'ОГРН',
    INN: 'ИНН',
}
