import { FuncRequired } from 'mmc-react-shared'
import QrScanner from 'qr-scanner'

export const SCAN_AREA_SIZE = 200
const LS_CAMERA_INDEX = 'camera_index'

class QrScannerHelper {
    private readonly _qrScanner: QrScanner
    private readonly _scanAreaSize: number

    private _handleScanSuccess?: FuncRequired<QrScanner.ScanResult>
    private _availableCameras: QrScanner.Camera[] = []

    constructor(
        videoElement: HTMLVideoElement,
        scanHighlight?: HTMLDivElement,
        onScanSuccess?: FuncRequired<QrScanner.ScanResult>,
        scanAreaSize = SCAN_AREA_SIZE
    ) {
        this._handleScanSuccess = onScanSuccess
        this._scanAreaSize = scanAreaSize

        this.destroy = this.destroy.bind(this)
        this.scan = this.scan.bind(this)
        this.switchCamera = this.switchCamera.bind(this)
        this._handleOrientationChange = this._handleOrientationChange.bind(this)

        this._calculateScanRegion = this._calculateScanRegion.bind(this)

        this._qrScanner = new QrScanner(videoElement, this._handleScan.bind(this), {
            highlightScanRegion: true,
            highlightCodeOutline: false,
            maxScansPerSecond: 1,
            calculateScanRegion: this._calculateScanRegion,
            overlay: scanHighlight,
        })

        screen.orientation.addEventListener('change', this._handleOrientationChange)
    }

    private async _handleOrientationChange() {
        await this._qrScanner.pause()

        await this._qrScanner.start()
    }

    private _calculateScanRegion(video: HTMLVideoElement) {
        return {
            x: Math.round((video.videoWidth - this._scanAreaSize) / 2),
            y: Math.round((video.videoHeight - this._scanAreaSize) / 2),
            width: this._scanAreaSize,
            height: this._scanAreaSize,
        }
    }

    private _handleScan(result: QrScanner.ScanResult) {
        this._handleScanSuccess?.(result)
    }

    get _cameraIndex() {
        const lsCurrentCameraIndex = localStorage.getItem(LS_CAMERA_INDEX)

        return lsCurrentCameraIndex == null ? 0 : +lsCurrentCameraIndex
    }

    set _cameraIndex(value: number) {
        localStorage.setItem(LS_CAMERA_INDEX, String(value))
    }

    private _prepareCamera() {
        const cameraId = this._availableCameras[this._cameraIndex]?.id

        if (cameraId) this._qrScanner.setCamera(cameraId)
    }

    async scan() {
        this._availableCameras = await QrScanner.listCameras(true)

        const cameraBackIndex = this._availableCameras.findIndex((camera) =>
            camera.label.includes('back')
        )

        if (cameraBackIndex !== -1 && !localStorage.getItem(LS_CAMERA_INDEX))
            this._cameraIndex = cameraBackIndex

        this._prepareCamera()

        return this._qrScanner
            .start()
            .then(() => this._availableCameras)
            .catch((error) => {
                throw error
            })
    }

    switchCamera() {
        if (this._cameraIndex < this._availableCameras.length - 1) {
            this._cameraIndex++
        } else {
            this._cameraIndex = 0
        }

        this._prepareCamera()
    }

    destroy() {
        this._qrScanner.destroy()
        screen.orientation.removeEventListener('change', this._handleOrientationChange)
    }
}

export default QrScannerHelper
