import { VITE_BASE_URL_WITHOUT_SLASH } from 'consts/env.consts'
import { UploadedFileContract } from 'api/generator/appApi'

export const getFileUrl = (file?: null | number | string) => {
    if (file === undefined || file === null) return

    const baseURL = VITE_BASE_URL_WITHOUT_SLASH

    return `${baseURL}/api/File/${file}`
}

export const downloadFile = (file?: UploadedFileContract) => {
    if (!file?.id) return

    const src = getFileUrl(file.id)!

    return fetch(src, {
        method: 'GET',
    })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob)

            const link = document.createElement('a')

            link.href = url
            link.download = `${`Документ_для_загрузки.${file.fileType}`}`

            link.click()

            URL.revokeObjectURL(link.href)
        })
}
