import React, { FC } from 'react'
import { CitizenWorkDataProps } from './CitizenWorkData.types'
import { Typography } from 'mmc-react-shared'
import { Col, Row } from 'antd'
import { GUTTER } from 'pages/CitizenCard/CitizenCard.consts'
import { CITIZEN_WORK_DATA_LOCALIZATION } from './CitizenWorkData.localization'
import { InformationGroup } from '../InformationGroup'
import { FieldView } from 'components/controls/FieldView'

export const CitizenWorkData: FC<CitizenWorkDataProps> = React.memo(({ workData }) => {
    return (
        <>
            <InformationGroup>
                <Row gutter={GUTTER}>
                    <Col span={24}>
                        <Typography weight="600">
                            {CITIZEN_WORK_DATA_LOCALIZATION.PATENT}
                        </Typography>
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_WORK_DATA_LOCALIZATION.WORK_STATUS}
                            value={workData?.status}
                            valueColor="text-custom-red-9"
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_WORK_DATA_LOCALIZATION.SERIES_NUMBER}
                            value={workData?.seriesNumber}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_WORK_DATA_LOCALIZATION.ISSUE_DATE}
                            value={workData?.issueDate}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_WORK_DATA_LOCALIZATION.EXPIRES_DATE}
                            value={workData?.expiresDate}
                        />
                    </Col>

                    <Col span={24} md={12}>
                        <FieldView
                            label={CITIZEN_WORK_DATA_LOCALIZATION.ISSUED_BY}
                            value={workData?.issuedBy}
                        />
                    </Col>

                    <Col span={24} md={12}>
                        <FieldView
                            label={CITIZEN_WORK_DATA_LOCALIZATION.LICENSE_TERRITORY}
                            value={workData?.licenseTerritory}
                        />
                    </Col>
                </Row>
            </InformationGroup>

            <InformationGroup>
                <Row gutter={GUTTER}>
                    <Col span={24}>
                        <Typography weight="600">
                            {CITIZEN_WORK_DATA_LOCALIZATION.EMPLOYER}
                        </Typography>
                    </Col>

                    <Col span={24} md={6}>
                        <FieldView
                            label={CITIZEN_WORK_DATA_LOCALIZATION.TYPE_EMPLOYER}
                            value={workData?.typeEmployer}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_WORK_DATA_LOCALIZATION.OGRN}
                            value={workData?.ogrn}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_WORK_DATA_LOCALIZATION.INN}
                            value={workData?.inn}
                        />
                    </Col>
                </Row>
            </InformationGroup>
        </>
    )
})
