import { DocumentType } from 'types/business.types'
import { IdentityDocument } from './components/IdentityDocument'
import { MigrationCard } from './components/MigrationCard'
import { ArrivalDocument } from './components/ArrivalDocument'
import { MedicalVerdict } from './components/MedicalVerdict'
import { HIVCertificate } from './components/HIVCertificate'
import { PsychiatristNarcologist } from './components/PsychiatristNarcologist'
import { PreviousFio } from './components/PreviousFio'
import { FingerprintCard } from './components/FingerprintCаrd'
import { InnDocument } from './components/InnDocument'
import { DmsDocument } from './components/DmsDocument'
import { ResidencePermit } from './components/ResidencePermit'
import { TemporaryResidencePermit } from './components/TemporaryResidencePermit'
import { ForeignCard } from './components/ForeignCard'
import { TestingDocument } from './components/TestingDocument'

export const DOCUMENT_DICTIONARY: Record<DocumentType, React.FC> = {
    identity: IdentityDocument,
    migration: MigrationCard,
    arrival: ArrivalDocument,
    medical: MedicalVerdict,
    'hiv-certificate': HIVCertificate,
    'psychiatrist-narcologist': PsychiatristNarcologist,
    'previous-fio': PreviousFio,
    'biometry-fingerprint': FingerprintCard,
    inn: InnDocument,
    dms: DmsDocument,
    'residence-permit': ResidencePermit,
    'temporary-residence-permit': TemporaryResidencePermit,
    'foreign-cards': ForeignCard,
    testing: TestingDocument,
}
