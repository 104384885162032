import { IntercepterExceptionsProps } from './api.types'

/**
 * @example `{ endpoint: '/api/RepresentedProfile/:id/personal', method: 'post' }`
 */
export const INTERCEPTER_RESPONSE_SUCCESS_EXCEPTIONS: IntercepterExceptionsProps[] = []

/**
 * @example `{ endpoint: '/api/RepresentedProfile/:id/personal', method: 'post' }`
 */
export const INTERCEPTER_RESPONSE_ERROR_EXCEPTIONS: IntercepterExceptionsProps[] = []
