import React, { FC, useCallback, useMemo, useState } from 'react'
import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Button, Alert } from 'mmc-react-shared'
import { EnterCardNumberValue } from './SearchByCard.types'
import { SEARCH_BY_CARD_LOCALIZATION } from './SearchByCard.localization'
import { useNavigator } from 'hooks/useNavigator'

import { LayoutOneColumn } from 'components/layouts/LayoutOneColumn'
import { InputControl } from 'components/controls/InputControl'
import { APP_LOCALIZATION } from 'localization'
import { FieldValidator } from 'mmc-react-shared/ant-design'
import { CARD_NUMBER_REGEXP, INPUT_TEST_CARD_NUMBER_REGEXP } from 'consts/profile.consts'

export const SearchByCard: FC = React.memo(() => {
    const [form] = useForm<EnterCardNumberValue>()
    const [disabledSearchBtn, setDisabledSearchBtn] = useState(true)
    const { goToHome, goToCitizenCard } = useNavigator()

    const handleSubmit = useCallback(
        ({ cardNum }: EnterCardNumberValue) => {
            goToCitizenCard(cardNum?.toUpperCase())
        },
        [goToCitizenCard]
    )

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setDisabledSearchBtn(!INPUT_TEST_CARD_NUMBER_REGEXP.test(e.target.value || ''))
    }, [])

    const { fieldRules: cardNumRules } = useMemo(
        () =>
            new FieldValidator().pattern(
                CARD_NUMBER_REGEXP,
                SEARCH_BY_CARD_LOCALIZATION.VALIDATION_ERROR_MESSAGE
            ),
        []
    )

    return (
        <LayoutOneColumn>
            <div className="flex flex-col justify-between h-full">
                <div className="flex justify-center">
                    <Alert
                        message={SEARCH_BY_CARD_LOCALIZATION.TITLE}
                        inlineFlex
                        className="text-center"
                    />
                </div>

                <div className="flex justify-center">
                    <Form form={form} onFinish={handleSubmit} className="basis-[312px]">
                        <InputControl
                            name="cardNum"
                            placeholder={SEARCH_BY_CARD_LOCALIZATION.PLACEHOLDER}
                            onChange={handleInputChange}
                            size="lg"
                            rules={cardNumRules}
                        />

                        <Button
                            type="dark"
                            htmlType="submit"
                            className="w-full"
                            disabled={disabledSearchBtn}
                        >
                            {SEARCH_BY_CARD_LOCALIZATION.SEARCH}
                        </Button>
                    </Form>
                </div>

                <div className="flex justify-center">
                    <Button
                        onClick={goToHome}
                        type="dark-outline"
                        className="min-w-[224px]"
                    >
                        {APP_LOCALIZATION.ACTIONS.SEARCH_BY_QR}
                    </Button>
                </div>
            </div>
        </LayoutOneColumn>
    )
})
