import React, { FC } from 'react'
import { InformationGroupProps } from './InformationGroup.types'
import styles from './InformationGroup.module.css'
import classNames from 'classnames'

export const InformationGroup: FC<InformationGroupProps> = React.memo(
    ({ children, className }) => {
        return (
            <div className={classNames(className, 'px-3 tablet:px-16')}>
                <div className={classNames(styles.content, 'py-6')}>{children}</div>
            </div>
        )
    }
)
