import { FC, memo } from 'react'
import cn from 'classnames'
import { TopControlPanelProps } from './TopControlPanel.types'
import { Icon, Typography } from 'mmc-react-shared'
import styles from './TopControlPanel.module.css'

export const TopControlPanel: FC<TopControlPanelProps> = memo(
    ({ title, onClose, onReturn, className }) => {
        return (
            <div
                className={cn(
                    styles.root,
                    'box-border px-4 flex items-center justify-between bg-custom-neutral-1',
                    className
                )}
            >
                <Icon
                    svgType="SvgIconArrowBackward16X16"
                    onClick={onReturn}
                    iconContainerPreset="24"
                    pathFill="text-custom-neutral-11"
                    className={cn(!onReturn && 'invisible')}
                />

                <Typography
                    type="heading-6"
                    weight="600"
                    className="m-0 py-3 tablet:py-4 w-full text-center"
                >
                    {title}
                </Typography>

                <Icon
                    svgType="SvgIconCloseRound14X14"
                    onClick={onClose}
                    pathFill="text-custom-neutral-11"
                    className={cn(!onClose && 'invisible')}
                />
            </div>
        )
    }
)
