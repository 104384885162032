import {
    useGetApiProfileByCardQuery,
    useGetApiProfileByQrCodeQuery,
} from 'api/generator/appApi'
import { CARD_NUMBER_REGEXP } from 'consts/profile.consts'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { CitizenCardUrlParams } from 'types/business.types'
import { dayjsSortHelper } from 'utils/date.utils'

export function useGetProfile() {
    const { uidOrCard } = useParams<CitizenCardUrlParams>()

    const isCardId = CARD_NUMBER_REGEXP.test(uidOrCard || '')

    const {
        data: profileByUuid,
        isLoading: isLoadingSearchByUuid,
        isError: isErrorSearchByUuid,
        isSuccess: isSuccessByUuid,
    } = useGetApiProfileByQrCodeQuery({ qrCodeData: uidOrCard }, { skip: isCardId })

    const {
        data: profileByNumber,
        isLoading: isLoadingSearchByNumber,
        isError: isErrorSearchByNumber,
        isSuccess: isSuccessByNumber,
    } = useGetApiProfileByCardQuery({ number: uidOrCard }, { skip: !isCardId })

    const isLoading = isLoadingSearchByUuid || isLoadingSearchByNumber
    const isError = isErrorSearchByUuid || isErrorSearchByNumber
    const isSuccess = isSuccessByUuid || isSuccessByNumber
    const profile = profileByUuid || profileByNumber

    const sortedProfile = useMemo(() => {
        if (!profile) return

        const { appeals, previousFioDocs, ...rest } = profile || {}

        const sortedAppeals = appeals
            ?.slice()
            .sort((a, b) => dayjsSortHelper(a.statusDate, b.statusDate))

        const sortedFioDocs = previousFioDocs
            ?.slice()
            .sort((a, b) => Number(b.eventNumber) - Number(a.eventNumber))

        return { ...rest, appeals: sortedAppeals, previousFioDocs: sortedFioDocs }
    }, [profile])

    return {
        isLoading,
        isError,
        isSuccess,
        profile: sortedProfile,
        uidOrCard,
    }
}
