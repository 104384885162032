import axios, { AxiosError } from 'axios'
import { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { VITE_BASE_URL_WITHOUT_SLASH } from 'consts/env.consts'
import { BaseQueryRequestProps } from './api.types'
import { interceptorsResponseReject, interceptorsResponseResolve } from './axios.utils'

export const axiosInstance = axios.create({
    headers: {
        /**
         * Для ASP.NET MVC Core бэкенда требуется передавать http заголовок
         * X-Requested-With, для того, чтобы он идентифицировал запрос, как
         * AJAX и возвращал 401 при неуспешной авторизации, а не 302.
         */
        'X-Requested-With': 'XMLHttpRequest',
    },
})

axiosInstance.interceptors.response.use(
    interceptorsResponseResolve,
    interceptorsResponseReject
)

export const axiosBaseQuery =
    (): BaseQueryFn<BaseQueryRequestProps, unknown, unknown> =>
    async ({ url, method, data, params, body }) => {
        try {
            const payload = data ?? body

            const result = await axiosInstance({
                url: VITE_BASE_URL_WITHOUT_SLASH + url,
                method,
                data: payload,
                params,
            })

            return { data: result.data }
        } catch (axiosError) {
            const err = axiosError as AxiosError

            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }
