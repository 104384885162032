import React, { FC } from 'react'
import { CitizenContactsDataProps } from './CitizenContactsData.types'
import { Typography } from 'mmc-react-shared'
import { Col, Row } from 'antd'
import { CITIZEN_CONTACTS_DATA_LOCALIZATION } from './CitizenContactsData.localization'
import { GUTTER } from 'pages/CitizenCard/CitizenCard.consts'

import { InformationGroup } from '../InformationGroup'
import { AddressFields } from '../AddressFields'
import { FieldView } from 'components/controls/FieldView'

export const CitizenContactsData: FC<CitizenContactsDataProps> = React.memo(
    ({
        registrationAddress,
        residenceAddress,
        addressesMatched,
        phoneNumber,
        additionalPhoneNumber,
        email,
    }) => {
        return (
            <>
                <InformationGroup>
                    <Row gutter={GUTTER}>
                        <AddressFields
                            title={
                                CITIZEN_CONTACTS_DATA_LOCALIZATION.REGISTRATION_ADDRESS
                            }
                            address={registrationAddress}
                        />
                    </Row>
                </InformationGroup>

                <InformationGroup>
                    <Row gutter={GUTTER}>
                        {addressesMatched ? (
                            <Col span={24}>
                                <Typography weight="600">
                                    {CITIZEN_CONTACTS_DATA_LOCALIZATION.ADDRESSES_MATCHED}
                                </Typography>
                            </Col>
                        ) : (
                            <AddressFields
                                title={
                                    CITIZEN_CONTACTS_DATA_LOCALIZATION.RESIDENCE_ADDRESS
                                }
                                address={residenceAddress}
                            />
                        )}
                    </Row>
                </InformationGroup>

                <InformationGroup>
                    <Row gutter={GUTTER}>
                        <Col span={24}>
                            <Typography weight="600">
                                {CITIZEN_CONTACTS_DATA_LOCALIZATION.CONTACTS}
                            </Typography>
                        </Col>

                        <Col span={12} md={6}>
                            <FieldView
                                label={CITIZEN_CONTACTS_DATA_LOCALIZATION.PHONE_NUMBER}
                                value={phoneNumber}
                            />
                        </Col>

                        <Col span={12} md={6}>
                            <FieldView
                                label={
                                    CITIZEN_CONTACTS_DATA_LOCALIZATION.ADDITIONAL_PHONE_NUMBER
                                }
                                value={additionalPhoneNumber}
                            />
                        </Col>

                        <Col span={12} md={6}>
                            <FieldView
                                label={CITIZEN_CONTACTS_DATA_LOCALIZATION.EMAIL}
                                value={email}
                            />
                        </Col>
                    </Row>
                </InformationGroup>
            </>
        )
    }
)
