import { Empty, LOCALIZATION } from 'mmc-react-shared'
import { FC } from 'react'
import { NO_RESULTS_FOUND_LOCALIZATION } from './NoResultsFound.localization'
import { LayoutOneColumn } from 'components/layouts/LayoutOneColumn'
import { useNavigator } from 'hooks/useNavigator'

export const NoResultsFound: FC = () => {
    const { goToHome } = useNavigator()

    return (
        <LayoutOneColumn>
            <Empty
                title={NO_RESULTS_FOUND_LOCALIZATION.HEADER}
                description={NO_RESULTS_FOUND_LOCALIZATION.TEXT}
                buttonTitle={LOCALIZATION.ACTIONS.CLOSE}
                buttonType="dark"
                onClick={goToHome}
                icon={false}
            />
        </LayoutOneColumn>
    )
}
