export const CITIZEN_PERSONAL_DATA_LOCALIZATION = {
    LASTNAME_RUS: 'Фамилия (рус.)',
    LASTNAME_ENG: 'Фамилия (лат.)',
    FIRSTNAME_RUS: 'Имя (рус.)',
    FIRSTNAME_ENG: 'Имя (лат.)',
    MIDDLENAME_RUS: 'Отчество (рус.)',
    MIDDLENAME_ENG: 'Отчество (лат.)',
    GENDER: 'Пол',
    DATE_OF_BIRTH: 'Дата рождения',
    CITIZENSHIP: 'Гражданство',
    COUNTRY_OF_BIRTH: 'Страна рождения',
    PLACE_OF_BIRTH: 'Место рождения',
}
