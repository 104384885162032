import { memo } from 'react'

import { createPortal } from 'react-dom'

import { FullscreenCanvas } from './components/FullscreenCanvas'
import { DocumentFullscreenProps } from './DocumentFullscreen.types'

export const DocumentFullscreen = memo(({ ...props }: DocumentFullscreenProps) => {
    const modalRoot = document.getElementById('modal-root')

    return createPortal(<FullscreenCanvas {...props} />, modalRoot!)
})
