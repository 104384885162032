export const MOCK_PERSONAL_DATA = {
    lastnameRus: 'Иванова',
    lastnameEng: 'Ivanova',
    firstnameRus: 'Светлана',
    firstnameEng: 'Svetlana',
    middlenameRus: 'Абдуллоевна',
    middlenameEng: 'Abdulloevna',
    gender: 'Женский',
    dateOfBirth: '11.12.2000',
    citizenship: 'Республика Узбекистан',
    coutryOfBirth: 'Республика Узбекистан',
    placeOfBirth: 'г. Ташкент',
    attention: false,
}

export const MOCK_DOCUMENTS_DATA = {
    list: [
        {
            name: 'Документ, удостоверяющий личность',
            value: 'AB 1234567',
            type: 'identity',
        },
        {
            name: 'Миграционная карта',
            value: 'AB 1234567',
            type: 'migration',
        },
        {
            name: 'Уведомление о прибытии иностранного гражданина',
            value: '16.01.2023',
            type: 'arrival',
        },
        {
            name: 'Медицинское заключение',
            value: 'Действителен до – 16.07.2024',
            type: 'medical',
        },
    ],
    attention: true,
}

export const MOCK_WORK_DATA = {
    attention: false,
    status: 'Не активен',
    seriesNumber: '50 108161707',
    issueDate: '02.07.2023',
    expiresDate: '02.07.2024',
    issuedBy: 'УФМС России по Московской области',
    licenseTerritory: 'Московская область',
    typeEmployer: 'Юридическое лицо',
    ogrn: '1234567890123',
    inn: '1234567890',
}

export const PERSONAL_INFORMATION_MOCKS = {
    lastname: 'Иванова',
    firstname: 'Светлана',
    middlename: 'Абдуллоевна',
    citizenship: 'Республика Узбекистан',
    cardNumber: 'AB 1234567',
    dateOfBirth: '11.12.2000',
    gender: 'Женский',
    attention: true,
}
