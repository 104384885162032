import { VITE_BASE_URL_WITHOUT_SLASH } from 'consts/env.consts'

import { IntercepterExceptionsProps } from './api.types'

export const matchIntercepterExceptions = (
    exceptions: IntercepterExceptionsProps[],
    requestUrl?: string,
    requestMethod?: string
) => {
    if (!requestUrl) return false

    return exceptions.some((path) => {
        const pathArr = `${VITE_BASE_URL_WITHOUT_SLASH}${path.endpoint}`.split('/')

        const urlArr = requestUrl.split('/')

        let match = true

        for (let i = 0; i < pathArr.length; i++) {
            if (pathArr[i].includes(':') && !!urlArr[i]) continue

            if (pathArr[i] !== urlArr[i]) match = false
        }

        return match && path.method === requestMethod?.toLowerCase()
    })
}

export const redirectToLoginPage = (url: string) => {
    window.location.href = url
}
