export const INFO_FIELDS_LOCALIZATION = {
    CITIZENSHIP: 'Гражданство',
    IDENTITY_DOC: 'Данные ДУЛ',
    DATE_OF_BIRTH: 'Дата рождения',
    GENDER: 'Пол',
    PATENT: 'Патент',
    FINGERPRINT: 'ОГДР',
    TESTING: 'Сертификат о прохождении тестирования',
    ISSUED_AT: 'Дата выдачи',
}
