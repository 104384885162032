import { configureStore } from '@reduxjs/toolkit'

import { appApi } from 'api/generator/appApi'

export const store = configureStore({
    reducer: {
        [appApi.reducerPath]: appApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(appApi.middleware),
})
