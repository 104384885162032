export const VITE_BASE_URL_WITHOUT_SLASH = (import.meta.env.VITE_BASE_URL || '').slice(
    0,
    -1
)

export const VITE_BASE_URL = import.meta.env.VITE_BASE_URL

export const IS_ENV_DEV = import.meta.env.DEV

export const IS_ENV_TEST = import.meta.env.MODE === 'test'
