import React, { FC } from 'react'
import classNames from 'classnames'
import { TagProps } from './Tag.types'
import { Tag as SharedTag } from 'mmc-react-shared'
import styles from './Tag.module.css'

export const Tag: FC<TagProps> = React.memo(({ withoutRestrictions, ...props }) => {
    return (
        <SharedTag
            {...props}
            className={classNames(
                withoutRestrictions && styles.withoutRestrictions,
                props.className
            )}
        />
    )
})
