import { memo } from 'react'

import { Icon, Typography } from 'mmc-react-shared'

import { FullscreenCanvasTopControlsProps } from './FullscreenCanvasTopControls.types'

export const FullscreenCanvasTopControls: React.FC<FullscreenCanvasTopControlsProps> =
    memo(
        ({
            currentFileIndex,
            fileType,
            totalFiles,
            onClose,
            onNextFullScreen,
            onPrevFullScreen,
            onRotate,
            onZoomIn,
            onZoomOut,
            resetCanvas,
        }) => {
            const isCurrentDocumentDocx = fileType === 'Docx'

            return (
                <div className="flex justify-between px-4 py-2 select-none">
                    <div className="flex justify-between gap-2">
                        <Icon
                            pathFill="text-custom-neutral-1"
                            iconContainerPreset="32"
                            svgType="SvgIconArrowAngleLeftFillRound9X16"
                            onClick={onPrevFullScreen}
                        />

                        <Typography
                            className="text-lg content-center"
                            textColor="text-custom-neutral-1"
                        >
                            {currentFileIndex + 1}/{totalFiles}
                        </Typography>

                        <Icon
                            pathFill="text-custom-neutral-1"
                            iconContainerPreset="32"
                            svgType="SvgIconArrowAngleRightFillRound9X16"
                            onClick={onNextFullScreen}
                        />
                    </div>

                    <div className="flex gap-2">
                        <Icon
                            svgType="SvgIconRotateLeftFill16X19"
                            iconContainerPreset="32"
                            pathFill="text-custom-neutral-1"
                            onClick={onRotate}
                            disabled={isCurrentDocumentDocx}
                            className="select-none"
                        />

                        <Icon
                            svgType="SvgIconPlusAltFill12X12"
                            iconContainerPreset="32"
                            pathStroke="text-custom-neutral-1"
                            size={16}
                            onClick={onZoomIn}
                            disabled={isCurrentDocumentDocx}
                            className="select-none"
                        />

                        <Icon
                            svgType="SvgIconMinusAltFill12X2"
                            iconContainerPreset="32"
                            pathStroke="text-custom-neutral-1"
                            size={16}
                            onClick={onZoomOut}
                            disabled={isCurrentDocumentDocx}
                            className="select-none"
                        />

                        <Icon
                            svgType="SvgIconCachedFill22X16"
                            iconContainerPreset="32"
                            pathFill="text-custom-neutral-1"
                            onClick={resetCanvas}
                            disabled={isCurrentDocumentDocx}
                            className="select-none"
                        />
                    </div>

                    <Icon
                        svgType="SvgIconCloseAlt16X16"
                        iconContainerPreset="32"
                        pathFill="text-custom-neutral-1"
                        onClick={onClose}
                    />
                </div>
            )
        }
    )
