export const CITIZEN_CONTACTS_DATA_LOCALIZATION = {
    REGION: 'Регион',
    CITY: 'Населённый пункт',
    BUILDING: 'Дом, корпус, строение',
    DISTRICT: 'Район',
    STREET: 'Улица',
    FLAT: 'Квартира №',
    REGISTRATION_ADDRESS: 'Адрес места регистрации',
    RESIDENCE_ADDRESS: 'Адрес места проживания',
    ADDRESSES_MATCHED: 'Адрес места проживания: совпадает с адресом места регистрации',
    CONTACTS: 'Контакты',
    PHONE_NUMBER: 'Номер телефона',
    ADDITIONAL_PHONE_NUMBER: 'Доп. номер телефона',
    EMAIL: 'Электронная почта',
}
