export const CANVAS_PARAMS = {
    SCALE_INITIAL: 1,
    SCALE_STEP: 0.25,

    ROTATE_INITIAL: 0,
    ROTATE_STEP: 90,
    ROTATE_MAX: 360,
    ROTATED_DEGS: [90, 270],

    INITIAL_TOUCH_START: 0,
    INITIAL_TOUCH_END: 0,
    LEFT_SWIPE_IDENTITY: 40,
    RIGHT_SWIPE_IDENTITY: -40,
}
