import React, { FC } from 'react'
import cn from 'classnames'
import { CollapseItemProps } from './CollapseItem.types'
import styles from './CollapseItem.module.css'
import { Icon } from 'mmc-react-shared'

export const CollapseItem: FC<CollapseItemProps> = React.memo(
    ({ children, onClick, dataScrollAttrKey, dataScrollAttrValue, isActive }) => {
        const dataAttr = {
            [`data-${dataScrollAttrKey}`]: dataScrollAttrValue,
        }

        return (
            <div
                className={cn(
                    styles.root,
                    'py-3 mx-0 tablet:py-6 tablet:mx-16',
                    'last:pb-6 last:tablet:pb-6 last:tablet:mb-6',
                    isActive && 'cursor-pointer'
                )}
                onClick={isActive ? onClick : undefined}
                {...dataAttr}
            >
                {children}

                {isActive && (
                    <Icon
                        pathFill="text-custom-neutral-19"
                        svgType="SvgIconChevronRightRight7X12"
                        iconContainerPreset="24"
                    />
                )}
            </div>
        )
    }
)
