import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
    Alert,
    Button,
    FuncRequired,
    Typography,
    useElementVisible,
} from 'mmc-react-shared'
import QrScannerHelper from 'helpers/QRScannerHelper'
import { useNavigator } from 'hooks/useNavigator'

import { SEARCH_BY_QR_LOCALIZATION } from './SearchByQR.localization'
import { LayoutOneColumn } from 'components/layouts/LayoutOneColumn'
import QrScanner from 'qr-scanner'

import ScannerHightlight from './scanner.svg?react'
import styles from './SearchByQR.module.css'
import iconSwitchCamera from 'assets/icons/switchCamera.svg'

export const SearchByQR: FC = React.memo(() => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const scanHighlightRef = useRef<HTMLDivElement>(null)
    const [scanError, setScanError] = useState<string | Error>()
    const qrScanner = useRef<QrScannerHelper | null>(null)
    const { goToHome, goToCitizenCard } = useNavigator()
    const {
        elementVisible: switchCameraBtnVisible,
        handleShowElement: showSwitchCameraBtn,
    } = useElementVisible()

    const searchByQR = useCallback<FuncRequired<QrScanner.ScanResult>>(
        (result) => {
            goToCitizenCard(result.data)
        },
        [goToCitizenCard]
    )

    useEffect(() => {
        if (videoRef.current && scanHighlightRef.current) {
            qrScanner.current = new QrScannerHelper(
                videoRef.current,
                scanHighlightRef.current,
                searchByQR
            )

            qrScanner.current
                .scan()
                .then((availableCameras) => {
                    if (availableCameras.length > 1) {
                        showSwitchCameraBtn()
                    }
                })
                .catch((error) => {
                    setScanError(error)
                })

            return () => {
                qrScanner.current?.destroy()
            }
        }
    }, [searchByQR, showSwitchCameraBtn])

    return (
        <LayoutOneColumn>
            <div className="absolute z-10 top-0 bottom-0 left-0 right-0 h-full overflow-hidden">
                <video ref={videoRef} className="object-cover h-full w-full" />

                <div ref={scanHighlightRef} className={styles.hightlight}>
                    <ScannerHightlight className="w-full h-full" />
                </div>
            </div>

            <div className="absolute box-border z-10 top-0 bottom-0 left-0 right-0 flex flex-col justify-between h-full py-12 px-4">
                <div className="flex justify-center">
                    {scanError ? (
                        <Alert
                            message={JSON.stringify(scanError)}
                            type="error"
                            withBorder
                            inlineFlex
                        />
                    ) : (
                        <Typography
                            type="heading-5"
                            text={SEARCH_BY_QR_LOCALIZATION.SCAN_QR_CODE_PROMPT}
                            textColor="text-custom-neutral-1"
                        />
                    )}
                </div>

                {switchCameraBtnVisible && (
                    <Button
                        onClick={qrScanner.current?.switchCamera}
                        type="dark"
                        size="small"
                        className="absolute top-4 right-4 "
                    >
                        <img
                            src={iconSwitchCamera}
                            alt={SEARCH_BY_QR_LOCALIZATION.SWITCH_CAMERA}
                            width={32}
                            height={32}
                        />
                    </Button>
                )}

                <div className="flex justify-center">
                    <Button onClick={goToHome} type="dark">
                        {SEARCH_BY_QR_LOCALIZATION.FINISH_SCAN}
                    </Button>
                </div>
            </div>
        </LayoutOneColumn>
    )
})
