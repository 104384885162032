import { FC } from 'react'
import { PersonalInformationProps } from './PersonalInformation.types'
import { PERSONAL_INFORMATION_LOCALIZATION } from './PersonalInformation.localization'
import { CitizenFIO } from 'components/business/CitizenFIO'
import { Attention } from 'components/common/Attention'
import { InfoFields } from 'components/business/InfoFields'
import classNames from 'classnames'
import { PersonInformationPhoto } from './PersonInformationPhoto'
import styles from './PersonalInformation.module.css'
import { TEST_IDS } from 'consts/tests.consts'

export const PersonalInformationViewMobile: FC<PersonalInformationProps> = ({
    name,
    surname,
    patronymic,
    citizenship,
    birthdate,
    gender,
    photoSrc,
    compactView,
    attention,
    testingDocs,
    patentDocs,
    biometryFingerprintDocs,
    identityDocs,
}) => {
    return (
        <div
            className="p-6 flex bg-custom-neutral-1 flex-wrap"
            data-testid={TEST_IDS.PERSONAL_INFORMATION_VIEW_MOBILE}
        >
            <div className="flex">
                <PersonInformationPhoto photoSrc={photoSrc} compactView={compactView} />

                <div className="ps-6">
                    <CitizenFIO
                        className={classNames(styles.FIOMobile, 'mb-3')}
                        name={name}
                        surname={surname}
                        patronymic={patronymic}
                    />

                    {attention && (
                        <Attention text={PERSONAL_INFORMATION_LOCALIZATION.ATTENTION} />
                    )}
                </div>
            </div>

            <InfoFields
                className={classNames('w-full mt-6', compactView && 'hidden')}
                citizenship={citizenship?.name}
                birthdate={birthdate}
                gender={gender}
                patentDocs={patentDocs}
                testingDocs={testingDocs}
                biometryFingerprintDocs={biometryFingerprintDocs}
                identityDocs={identityDocs}
            />
        </div>
    )
}
