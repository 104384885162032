import { useCallback, useEffect, useState } from 'react'

export function useOffline() {
    const [offline, setOffline] = useState(!window.navigator.onLine)

    useEffect(() => {
        const handleOffline = () => {
            setOffline(true)
            console.log('Потеряно подключение к Интернету.')
        }

        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('offline', handleOffline)
        }
    }, [])

    const updateConnectionStatus = useCallback(() => {
        setOffline(!window.navigator.onLine)
    }, [])

    return {
        offline,
        updateConnectionStatus,
    }
}
