import React, { FC } from 'react'
import cn from 'classnames'
import { CitizenAppealsProps } from './CitizenAppeals.types'
import { useParams } from 'react-router-dom'
import { CitizenCardUrlParams } from 'types/business.types'
import { useNavigator } from 'hooks/useNavigator'
import { AppealContract } from 'api/generator/appApi'
import { FieldView } from 'components/controls/FieldView'
import { formatDate } from 'mmc-react-shared'
import styles from './CitizenAppeals.module.css'
import { CollapseItem } from 'components/business/CollapseItem'
import { useScrollTo } from 'hooks/useScrollTo'
import classNames from 'classnames'

export const CitizenAppeals: FC<CitizenAppealsProps> = React.memo(({ appeals }) => {
    const { uidOrCard } = useParams<CitizenCardUrlParams>()

    const { goToCitizenCardAppeal } = useNavigator()

    const dataScrollAttr = 'appeal-number'

    useScrollTo(dataScrollAttr)

    const renderAppeal = (appeal: AppealContract) => {
        if (!appeal.mainService || !appeal.number) return

        const handleClick = () => {
            if (!appeal) return

            goToCitizenCardAppeal(uidOrCard, appeal.number)
        }

        return (
            <CollapseItem
                key={appeal.number}
                onClick={handleClick}
                dataScrollAttrKey={dataScrollAttr}
                dataScrollAttrValue={appeal.number}
                isActive
            >
                <div
                    className={cn(
                        'mt-1 ml-1 mr-3',
                        styles.status,
                        appeal.statusCode && styles[appeal.statusCode]
                    )}
                />

                <FieldView
                    label={appeal.mainService}
                    value={`${appeal.status} - ${formatDate(appeal.statusDate)}`}
                    className={classNames(styles.fieldView, '!mb-0')}
                />
            </CollapseItem>
        )
    }

    return appeals.map(renderAppeal)
})
