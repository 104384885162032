import { FieldView as FieldViewOrigin, FieldViewProps } from 'mmc-react-shared'
import { FC } from 'react'

export const FieldView: FC<FieldViewProps> = (props) => (
    <FieldViewOrigin
        {...props}
        labelColor="text-custom-neutral-13"
        value={props.value?.toString().trim()}
        valuePlaceholder="-"
    />
)
