import {
    AccountPermissionContract,
    MedicalDocumentTypeContract,
    MedicalDocumentWithFilesInfoContract,
} from 'api/generator/appApi'
import dayjs from 'dayjs'
import { dayjsDateIsValidStrict, formatDate, Func } from 'mmc-react-shared'
import { ReactNode } from 'react'

export const joinTrue = (
    arr?: (string | null | undefined | boolean | number)[],
    separator?: string
) => {
    if (!arr || !arr.length) return

    return arr
        .filter((val) => val != null && val !== '')
        .join(separator == null ? ' ' : separator)
}

export const mapString = (str?: string | null, mapFn?: Func<string, string>) => {
    if (!str) return ''

    if (!mapFn) return str

    return Array.from(str, mapFn).join('')
}

interface GetSeriesAndNumberProps {
    series?: string | null
    number?: string | null
}

export const getSeriesAndNumber = <T extends GetSeriesAndNumberProps>(props?: T) => {
    if (!props) return

    const { series, number } = props

    return joinTrue([series, number])
}

interface GetIssuedAtProps {
    issuedAt?: string | null
}

export const getIssuedAt = <T extends GetIssuedAtProps>(props?: T) => {
    if (!props) return

    const { issuedAt } = props

    if (!dayjsDateIsValidStrict(issuedAt)) return

    return `от ${formatDate(issuedAt)}`
}

interface GetIssuerProps {
    issuer?: string | null
}

export const getIssuer = <T extends GetIssuerProps>(props?: T) => {
    if (!props) return

    const { issuer } = props

    if (!issuer) return

    return `выдано ${issuer}`
}

interface GetShortNameProps {
    name?: string | null
    surname?: string | null
    patronymic?: string | null
}

export const getFullName = <T extends GetShortNameProps>(props?: T | null) => {
    if (!props) return

    const { name, surname, patronymic } = props

    return joinTrue([surname, name, patronymic])
}

export const getFIO = <T extends GetShortNameProps>(props?: T | null) => {
    if (!props) return

    const { name, surname, patronymic } = props

    const nameAndPatronymic = joinTrue([name?.[0], patronymic?.[0]], '')
    const nameAndPatronymicShort = mapString(nameAndPatronymic, (char) => char + '.')

    return joinTrue([surname, nameAndPatronymicShort])
}

export const authLogout = () => {
    window.location.href = '/profile-registry/auth/logout'
}

interface GetLatestDocProps {
    issuedAt?: string | null
}

export const getLatestDoc = <T extends GetLatestDocProps>(docs?: Array<T> | null) => {
    if (!docs) return

    const tempDocs = [...docs]

    tempDocs.sort((a, b) => {
        return dayjs(b.issuedAt).unix() - dayjs(a.issuedAt).unix()
    })

    return tempDocs[0]
}

export const getMedicalDocsByType = (
    type: MedicalDocumentTypeContract,
    docs?: MedicalDocumentWithFilesInfoContract[] | null
) => docs?.find(({ medicalDocumentType }) => medicalDocumentType === type)

export const getJsxIfTrue = (
    condition: unknown,
    jsx: ReactNode,
    placeholder?: ReactNode
) => (condition ? jsx : placeholder)

export const getDataIfTrue = <T>(condition: unknown, data: T) =>
    condition ? data : undefined

export const hasPermissions = (
    userPermissions?: AccountPermissionContract[] | null,
    permissions?: AccountPermissionContract | AccountPermissionContract[]
) => {
    if (!userPermissions?.length || !permissions?.length) return false

    if (Array.isArray(permissions)) {
        return permissions.every((permission) => userPermissions?.includes(permission))
    }

    return userPermissions?.includes(permissions)
}
