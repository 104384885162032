import React, { FC } from 'react'
import { CitizenFIOProps } from './CitizenFIO.types'
import { getFullName } from 'utils/common.utils'
import { TEST_IDS } from 'consts/tests.consts'

export const CitizenFIO: FC<CitizenFIOProps> = React.memo(
    ({
        name,
        surname,
        patronymic,
        className,
        dataTestId = TEST_IDS.CITIZEN_FIO,
        componentRef,
        style,
    }) => {
        const fullName = getFullName({ name, patronymic })

        return (
            <div
                className={className}
                data-testid={dataTestId}
                ref={componentRef}
                style={style}
            >
                {surname}

                {fullName && (
                    <>
                        {surname && <br />}

                        {fullName}
                    </>
                )}
            </div>
        )
    }
)
