import React, { FC } from 'react'
import { Icon, Typography } from 'mmc-react-shared'
import { CollapseLabelProps } from './CollapseLabel.types'

export const CollapseLabel: FC<CollapseLabelProps> = React.memo(
    ({ title, svgIconType }) => {
        return (
            <Typography type="heading-6" weight="600" className="flex items-center">
                <span className="flex items-center justify-center size-10 rounded-s bg-custom-neutral-2 mr-2 tablet:mr-3">
                    <Icon svgType={svgIconType} pathFill="text-custom-neutral-15" />
                </span>

                {title}
            </Typography>
        )
    }
)
