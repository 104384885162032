import { ReactNode } from 'react'
import { CollapseLabel } from 'components/common/Collapse'
import { CollapseExtra } from 'components/common/Collapse/CollapseExtra'
import { CitizenPersonalData } from './components/CitizenPersonalData'
import { CITIZEN_CARD_LOCALIZATION } from './CitizenCard.localization'
import { CitizenContactsData } from './components/CitizenContactsData'
import { ROUTES } from 'routing/routes'
import some from 'lodash/some'
import {
    GetApiProfileByCardApiResponse,
    HierarchyItemContract,
    HierarchyItemTypesContract,
    UserContract,
} from 'api/generator/appApi'
import { formatDate } from 'mmc-react-shared'
import { CitizenDocuments } from './components/CitizenDocuments'
import { CitizenWorkData } from './components/CitizenWorkData'
import { MOCK_WORK_DATA } from './CitizenCard.mocks'
import {
    getDataIfTrue,
    getJsxIfTrue,
    getMedicalDocsByType,
    getSeriesAndNumber,
    hasPermissions,
} from 'utils/common.utils'
import { CommonDocumentProps } from './CitizenCard.types'
import { ShortDocumentInfo } from './components/CitizenDocuments/CitizenDocuments.types'
import { formatDateWithExpiration, formatDateWithYearOfChange } from 'utils/date.utils'
import { HierarchyType, StoreUnionKeyPartial } from 'types/common.types'
import { ItemType } from '../../../node_modules/rc-collapse/es/interface.d'
import { APP_LOCALIZATION } from 'localization'
import { APPEAL_ATTENTION_STATUSES } from './CitizenCard.consts'
import { CitizenAppeals } from './components/CitizenAppeals'
import { CitizenEmptyData } from './components/CitizenEmptyData'
import includes from 'lodash/includes'

const mergeDataShemeAndHierarchy = <T,>(
    hierarchy?: HierarchyItemContract[],
    data?: StoreUnionKeyPartial<HierarchyItemTypesContract, T | undefined>
) => {
    if (!hierarchy || !data) return

    const result = hierarchy.reduce<T[]>((acc, { isHidden, itemName }) => {
        if (isHidden || !itemName || !data[itemName]) return acc

        return [...acc, data[itemName] as T]
    }, [])

    return result
}

export const getCitizenCardCollapseScheme = (
    profile?: GetApiProfileByCardApiResponse,
    hierarchyBaseInfoAndDocs?: HierarchyType,
    userPermissions?: UserContract['permissions'],
    excludeKeys?: string[]
) => {
    if (!profile || !hierarchyBaseInfoAndDocs) return []

    const {
        surname,
        name,
        patronymic,
        surnameLatin,
        nameLatin,
        patronymicLatin,
        birthdate,
        citizenship,
        countryOfBirth,
        placeOfBirth,
        addressesMatched,
        registrationAddress,
        residenceAddress,
        phoneNumber,
        additionalPhoneNumber,
        email,
        gender,
        patentDocs,
        appeals,
        ...commonDocuments
    } = profile

    const personal = {
        surname,
        name,
        patronymic,
        surnameLatin,
        nameLatin,
        patronymicLatin,
        gender: gender && APP_LOCALIZATION.DICTIONARIES.GENDER[gender],
        birthdate: formatDate(birthdate),
        citizenship: citizenship?.name,
        countryOfBirth: countryOfBirth?.name,
        placeOfBirth: placeOfBirth,
        attention: false,
    }

    const contacts = {
        registrationAddress,
        residenceAddress,
        addressesMatched,
        phoneNumber,
        additionalPhoneNumber,
        email,
        attention: false,
    }

    const documentsList = getDocumentsList(
        commonDocuments,
        hierarchyBaseInfoAndDocs.documents
    )

    const isAppealsAttentionVisible = appeals?.some((appeal) =>
        includes(APPEAL_ATTENTION_STATUSES, appeal.statusCode)
    )

    const isDocumentsAttentionVisible = false

    const getChildrenIfTrue = (condition: unknown, jsx: ReactNode) =>
        getJsxIfTrue(condition, jsx, <CitizenEmptyData />)

    const baseInfoCollapseScheme: StoreUnionKeyPartial<
        HierarchyItemTypesContract,
        ItemType | undefined
    > = {
        Documents: getDataIfTrue(hasPermissions(userPermissions, 'DocsView'), {
            key: ROUTES['citizen-card/:uidOrCard'].documents._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.DOCUMENTS_TITLE}
                    svgIconType="SvgIconFolderOutline20X16"
                />
            ),
            extra: isDocumentsAttentionVisible && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: getChildrenIfTrue(
                some(documentsList, 'value'),
                <CitizenDocuments documents={documentsList as ShortDocumentInfo[]} />
            ),
        }),
        Labour: getDataIfTrue(hasPermissions(userPermissions, 'LaborView'), {
            key: ROUTES['citizen-card/:uidOrCard'].work._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.WORK_DATA_TITLE}
                    svgIconType="SvgIconWorkOutline20X19"
                />
            ),
            extra: MOCK_WORK_DATA.attention && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: getChildrenIfTrue(
                patentDocs?.[0],
                <CitizenWorkData workData={MOCK_WORK_DATA} />
            ),
        }),
        PersonalInfo: getDataIfTrue(hasPermissions(userPermissions, 'PersonaView'), {
            key: ROUTES['citizen-card/:uidOrCard'].personal._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.PERSONAL_DATA_TITLE}
                    svgIconType="SvgIconPersonOutline16X16"
                />
            ),
            extra: personal?.attention && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: getChildrenIfTrue(
                some(personal),
                <CitizenPersonalData {...personal} />
            ),
        }),
        Contacts: getDataIfTrue(hasPermissions(userPermissions, 'ContactView'), {
            key: ROUTES['citizen-card/:uidOrCard'].contacts._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.ADDRESS_AND_CONTACTS_TITLE}
                    svgIconType="SvgIconPlaceOutline14X20"
                />
            ),
            extra: contacts?.attention && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: getChildrenIfTrue(
                some(contacts),
                <CitizenContactsData {...contacts} />
            ),
        }),
        Appeals: getDataIfTrue(hasPermissions(userPermissions, 'AppealView'), {
            key: ROUTES['citizen-card/:uidOrCard'].appeals._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.APPEALS_TITLE}
                    svgIconType="SvgIconTextboxPlusOutline19X19"
                />
            ),
            extra: isAppealsAttentionVisible && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: getChildrenIfTrue(
                appeals?.length,
                <CitizenAppeals appeals={appeals!} />
            ),
        }),
    }

    const mergedData = mergeDataShemeAndHierarchy(
        hierarchyBaseInfoAndDocs.baseInfo,
        baseInfoCollapseScheme
    )

    return excludeKeys
        ? mergedData?.filter((dataPart) => !excludeKeys.includes(String(dataPart.key)))
        : mergedData
}

function getDocumentsList(
    {
        arrivalDocs,
        biometryFingerprintDocs,
        identityDocs,
        medicalDocs,
        migrationCards,
        previousFioDocs,
        innDocs,
        dmsDocs,
        residencePermitDocs,
        temporaryResidencePermitDocs,
        foreignCards,
        testingDocs,
    }: CommonDocumentProps,
    hierarchyDocuments?: HierarchyItemContract[]
) {
    const medicalVerdict = getMedicalDocsByType('MedicalVerdict', medicalDocs)
    const HIVCertificate = getMedicalDocsByType('HIVCertificate', medicalDocs)
    const psychiatristNarcologistVerdict = getMedicalDocsByType(
        'PsychiatristNarcologistVerdict',
        medicalDocs
    )

    const documentsScheme: StoreUnionKeyPartial<
        HierarchyItemTypesContract,
        ShortDocumentInfo | undefined
    > = {
        IdentityDocument: {
            name: CITIZEN_CARD_LOCALIZATION.IDENTITY_DOCUMENT,
            value: getSeriesAndNumber(identityDocs?.[0]),
            type: 'identity',
        },
        MigrationCard: {
            name: CITIZEN_CARD_LOCALIZATION.MIGRATION_DOCUMENT,
            value: getSeriesAndNumber(migrationCards?.[0]),
            type: 'migration',
        },
        ArrivalNotification: {
            name: CITIZEN_CARD_LOCALIZATION.ARRIVAL_DOCUMENT,
            value: formatDate(arrivalDocs?.[0]?.stayExpiresAt),
            type: 'arrival',
        },
        MedicalReport: {
            name: CITIZEN_CARD_LOCALIZATION.MEDICAL_VERDICT,
            value: formatDateWithExpiration(medicalVerdict?.expirationDate),
            type: 'medical',
        },
        HivCertificate: {
            name: CITIZEN_CARD_LOCALIZATION.HIV_CERTIFICATE,
            value: formatDateWithExpiration(HIVCertificate?.expirationDate),
            type: 'hiv-certificate',
        },
        PsychiatricReport: {
            name: CITIZEN_CARD_LOCALIZATION.PSYCHIATRRIST_NARCOLOGIST_VERDICT,
            value: formatDateWithExpiration(
                psychiatristNarcologistVerdict?.expirationDate
            ),
            type: 'psychiatrist-narcologist',
        },
        NameChangeDocument: {
            name: CITIZEN_CARD_LOCALIZATION.PREVIOUS_FIO,
            value: formatDateWithYearOfChange(previousFioDocs?.[0]?.year),
            type: 'previous-fio',
        },
        FingerprintRegistrationDocument: {
            name: CITIZEN_CARD_LOCALIZATION.BIOMETRY_FINGERPRINT,
            value: getSeriesAndNumber(biometryFingerprintDocs?.[0]),
            type: 'biometry-fingerprint',
        },
        Inn: {
            name: CITIZEN_CARD_LOCALIZATION.INN_DOCUMENT,
            value: innDocs?.[0]?.innNumber,
            type: 'inn',
        },
        DmsPolicy: {
            name: CITIZEN_CARD_LOCALIZATION.DMS_DOCUMENT,
            value: dmsDocs?.[0]?.number,
            type: 'dms',
        },
        ResidencyPermit: {
            name: CITIZEN_CARD_LOCALIZATION.RESIDENCE_PERMIT_DOCUMENT,
            value: formatDateWithExpiration(residencePermitDocs?.[0]?.expirationDate),
            type: 'residence-permit',
        },
        TemporaryResidencePermit: {
            name: CITIZEN_CARD_LOCALIZATION.TEMPORARY_RESIDENCE_PERMIT_DOCUMENT,
            value: formatDateWithExpiration(
                temporaryResidencePermitDocs?.[0]?.expirationDate
            ),
            type: 'temporary-residence-permit',
        },
        ElectronicCitizenCard: {
            name: CITIZEN_CARD_LOCALIZATION.FOREIGN_CARD_DOCUMENT,
            value: formatDateWithExpiration(foreignCards?.[0]?.expirationDate),
            type: 'foreign-cards',
        },
        Testing: {
            name: CITIZEN_CARD_LOCALIZATION.TESTING_DOCUMENT,
            value: getSeriesAndNumber(testingDocs?.[0]),
            type: 'testing',
        },
    }

    return mergeDataShemeAndHierarchy(hierarchyDocuments, documentsScheme)
}
