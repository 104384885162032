import React, { FC } from 'react'
import { Header } from '../Header'
import { PREVIOUS_FIO_LOCALIZATION } from './PreviousFio.localization'
import { Col, Row } from 'antd'
import { FieldView } from 'components/controls/FieldView'
import { useGetProfile } from 'hooks/useGetProfile'
import { PreviousFioDocumentWithFilesInfoContract } from 'api/generator/appApi'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocuments/CitizenCardDocuments.consts'
import { DocumentView } from 'components/controls/DocumentView'

export const PreviousFio: FC = React.memo(() => {
    const { profile } = useGetProfile()

    const documents = profile?.previousFioDocs || []

    const renderDocument = (
        document: PreviousFioDocumentWithFilesInfoContract,
        index: number
    ) => (
        <Row
            key={index}
            gutter={CITIZEN_DOC_GRID_ROW.gutter}
            className="tablet:px-16 border-0 border-b border-solid border-custom-neutral-5 pb-4 mb-4 last:pb-0 last:border-0"
        >
            <Col {...CITIZEN_DOC_GRID_COL}>
                <FieldView label={PREVIOUS_FIO_LOCALIZATION.YEAR} value={document.year} />
            </Col>

            <Col {...CITIZEN_DOC_GRID_COL}>
                <FieldView
                    label={PREVIOUS_FIO_LOCALIZATION.PREVIOUS_FIO}
                    value={document.previousFio}
                />
            </Col>

            <Col {...CITIZEN_DOC_GRID_COL}>
                <FieldView
                    label={PREVIOUS_FIO_LOCALIZATION.REASON}
                    value={document.reason}
                />
            </Col>

            <Col xs={24}>
                <DocumentView files={document.files} />
            </Col>
        </Row>
    )

    return (
        <>
            <Header title={PREVIOUS_FIO_LOCALIZATION.TITLE} />

            {documents.map(renderDocument)}
        </>
    )
})
