import React, { FC } from 'react'
import { PersonalInformationProps } from './PersonalInformation.types'
import { PersonalInformationViewMobile } from './PersonalInformationViewMobile'
import { PersonalInformationViewTablet } from './PersonalInformationViewTablet'

import { useBreakpoint } from 'mmc-react-shared'

export const PersonalInformation: FC<PersonalInformationProps> = React.memo((props) => {
    const isTablet = useBreakpoint('Tablet')

    const ViewComponent = isTablet
        ? PersonalInformationViewTablet
        : PersonalInformationViewMobile

    return <ViewComponent {...props} />
})
