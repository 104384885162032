import React, { FC } from 'react'
import { Header } from '../Header'
import { RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION } from './ResidencePermit.localization'
import { Col, Row } from 'antd'
import { FieldView } from 'components/controls/FieldView'
import { useGetProfile } from 'hooks/useGetProfile'
import { formatDate } from 'mmc-react-shared'
import { getSeriesAndNumber } from 'utils/common.utils'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocuments/CitizenCardDocuments.consts'
import { DocumentView } from 'components/controls/DocumentView'

export const ResidencePermit: FC = React.memo(() => {
    const { profile } = useGetProfile()

    const document = profile?.residencePermitDocs?.[0] || {}

    return (
        <>
            <Header title={RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.TITLE} />

            <Row {...CITIZEN_DOC_GRID_ROW}>
                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.SERIES_AND_NUMBER}
                        value={getSeriesAndNumber(document)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={
                            RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.BLANK_SERIES_AND_NUMBER
                        }
                        value={getSeriesAndNumber({
                            series: document.blankSeries,
                            number: document.blankNumber,
                        })}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.ISSUED_AT}
                        value={formatDate(document.issuedAt)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.EXPIRATION_DATE}
                        value={formatDate(document.expirationDate)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.ISSUER}
                        value={document.issuer}
                    />
                </Col>

                <Col xs={24}>
                    <DocumentView files={document.files} />
                </Col>
            </Row>
        </>
    )
})
