import throttle from 'lodash/throttle'
import { RefObject, useEffect, useState } from 'react'

export function useOffsetTop<E extends HTMLElement>(ref: RefObject<E>) {
    const [offsetTop, setOffsetTop] = useState<number>()

    useEffect(() => {
        const timeout = 200

        const interval = setInterval(() => {
            setOffsetTop(ref.current?.offsetTop)

            if (ref.current?.offsetTop) clearInterval(interval)
        }, timeout)

        const handleResize = throttle(() => {
            setOffsetTop(ref.current?.offsetTop)
        }, timeout)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
            handleResize.cancel()
            clearInterval(interval)
        }
    }, [ref])

    return offsetTop
}
