import { VITE_BASE_URL } from 'consts/env.consts'
import { buildRoutes } from 'mmc-react-shared'

const ROUTER_SCHEME = {
    'search-by-qr': {},
    'search-by-card': {},
    'no-results': {},
    'citizen-card/:uidOrCard': {
        work: {},
        exit: {},
        contacts: {},
        personal: {},
        appeals: {
            ':appealNumber': {},
        },
        documents: {
            ':documentType': {},
        },
    },
    logout: {},
}

export const ROUTES = buildRoutes(ROUTER_SCHEME, VITE_BASE_URL)
