import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'
import { TopControlPanel } from 'components/layouts/TopControlPanel'
import { PersonalInformation } from 'components/business/PersonalInformation'
import { useNavigator } from 'hooks/useNavigator'
import { Collapse } from 'components/common/Collapse'
import { getCitizenCardCollapseScheme } from 'pages/CitizenCard/CitizenCard.utils'
import last from 'lodash/last'
import { ROUTES } from 'routing/routes'
import { useGetProfile } from 'hooks/useGetProfile'
import { Preloader } from 'mmc-react-shared'
import { Layout } from 'mmc-react-shared'
import { getFileUrl } from 'utils/file.utils'
import { useScrollOutsideBorder } from 'hooks/useScrollOutsideBorder'
import { useHierarchyBaseInfoAndDocs } from 'hooks/useHierarchyBaseInfoAndDocs'
import { useOffsetTop } from 'hooks/useTopElement'
import { useGetApiUserQuery } from 'api/generator/appApi'

export const CitizenCard: FC = React.memo(() => {
    const headerRef = useRef<HTMLDivElement>(null)

    const {
        profile,
        isLoading: isLoadingProfile,
        isError: isErrorProfile,
        isSuccess,
        uidOrCard,
    } = useGetProfile()

    const { data: user } = useGetApiUserQuery()

    const { hierarchyBaseInfoAndDocs, isLoadingHierarchy, isErrorHierarchy } =
        useHierarchyBaseInfoAndDocs()

    const location = useLocation().pathname.split('/')

    const compactVeiwOffsetTop = useOffsetTop(headerRef)

    const { isScrollOutsideBorders, scrollToTopButton } = useScrollOutsideBorder(
        headerRef.current?.clientHeight
    )

    const { goToExit, goTo } = useNavigator()

    const handleClose = useCallback(() => {
        goToExit(uidOrCard)
    }, [goToExit, uidOrCard])

    // 'work' скрыто в рамках задачи https://jira.it2g.ru/browse/MMCSERV-11527
    const items = useMemo(() => {
        return getCitizenCardCollapseScheme(
            profile,
            hierarchyBaseInfoAndDocs,
            user?.permissions,
            ['work']
        )
    }, [hierarchyBaseInfoAndDocs, profile, user])

    useEffect(() => {
        if (isSuccess && !profile) {
            goTo(ROUTES['no-results']._absolute)
        }
    }, [isSuccess, goTo, profile])

    const photoSrc = getFileUrl(profile?.personalPhotoFile?.id)

    const isLoading = isLoadingProfile || isLoadingHierarchy
    const isError = isErrorProfile || isErrorHierarchy

    return (
        <Preloader isLoading={isLoading} isError={isError} positionCenter>
            <Layout
                className="bg-custom-neutral-4"
                mainClassName="h-full flex-auto"
                headerContent={
                    <TopControlPanel
                        title={profile?.foreignCards?.[0].cardNumber}
                        onClose={handleClose}
                    />
                }
                headerClassName="z-20"
                headerSticky
            >
                <div className="h-full w-full">
                    <div ref={headerRef} className="shadow-custom">
                        {!!profile && (
                            <PersonalInformation
                                name={profile.name}
                                surname={profile.surname}
                                patronymic={profile.patronymic}
                                citizenship={profile.citizenship}
                                birthdate={profile.birthdate}
                                gender={profile.gender}
                                photoSrc={photoSrc}
                                attention={profile.isWanted}
                                testingDocs={profile.testingDocs}
                                biometryFingerprintDocs={profile.biometryFingerprintDocs}
                                patentDocs={profile.patentDocs}
                                identityDocs={profile.identityDocs}
                            />
                        )}
                    </div>

                    <div
                        className={cn(
                            'fixed left-0 right-0 z-10 shadow-custom',
                            !isScrollOutsideBorders && 'hidden'
                        )}
                        style={{ top: compactVeiwOffsetTop }}
                    >
                        {!!profile && (
                            <PersonalInformation
                                name={profile.name}
                                surname={profile.surname}
                                patronymic={profile.patronymic}
                                citizenship={profile.citizenship}
                                birthdate={profile.birthdate}
                                gender={profile.gender}
                                photoSrc={photoSrc}
                                attention={profile.isWanted}
                                compactView
                            />
                        )}
                    </div>

                    <Collapse items={items} defaultActiveKey={last(location)} />

                    {scrollToTopButton}
                </div>
            </Layout>
        </Preloader>
    )
})
