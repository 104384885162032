import { useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from 'routing/routes'

export function useNavigator() {
    const navigate = useNavigate()

    const navigator = useMemo(
        () => ({
            goToHome() {
                navigate(ROUTES._basePath)
            },
            goToSearchByQR() {
                navigate(ROUTES['search-by-qr']._absolute)
            },
            goToSearchByCard() {
                navigate(ROUTES['search-by-card']._absolute)
            },
            goToCitizenCard(uidOrCard?: string) {
                if (!uidOrCard) return

                const path = generatePath(ROUTES['citizen-card/:uidOrCard']._absolute, {
                    uidOrCard,
                })

                navigate(path)
            },
            goToCitizenCardDocument(uidOrCard?: string, documentType?: string | null) {
                if (!uidOrCard || !documentType) return

                const path = generatePath(
                    ROUTES['citizen-card/:uidOrCard'].documents[':documentType']
                        ._absolute,
                    { uidOrCard, documentType }
                )

                navigate(path)
            },
            goToCitizenCardAppeal(uidOrCard?: string, appealNumber?: string | null) {
                if (!uidOrCard || !appealNumber) return

                const path = generatePath(
                    ROUTES['citizen-card/:uidOrCard'].appeals[':appealNumber']._absolute,
                    { uidOrCard, appealNumber }
                )

                navigate(path)
            },
            goToExit(uidOrCard?: string) {
                if (!uidOrCard) return

                navigate(
                    generatePath(ROUTES['citizen-card/:uidOrCard'].exit._absolute, {
                        uidOrCard,
                    })
                )
            },
            goToLogout() {
                navigate(ROUTES.logout._absolute)
            },
            goBack() {
                navigate(-1)
            },
            goTo(path: string) {
                navigate(path)
            },
        }),
        [navigate]
    )

    return navigator
}
