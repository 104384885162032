import useResizeObserver from '@react-hook/resize-observer'
import { RefObject, useLayoutEffect, useState } from 'react'

interface SizeProps {
    x?: number
    y?: number
    width?: number
    height?: number
}

export function useSize<E extends HTMLElement>(target: RefObject<E>) {
    const [size, setSize] = useState<SizeProps>()

    useLayoutEffect(() => {
        setSize(target.current?.getBoundingClientRect())
    }, [target])

    useResizeObserver(target, (entry) => setSize(entry.contentRect))

    return size || {}
}
