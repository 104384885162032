import React, { FC } from 'react'
import { Layout } from 'mmc-react-shared'

export const NotFound: FC = React.memo(() => {
    return (
        <Layout>
            <div>404</div>
        </Layout>
    )
})
