import { memo } from 'react'

import { Typography } from 'mmc-react-shared'

import { APP_LOCALIZATION } from 'localization'

export const CitizenEmptyData = memo(() => (
    <Typography
        className="pt-5 pb-8 tablet:px-3 tablet:pb-0"
        textColor="text-custom-neutral-11"
    >
        <span className="ps-10 ms-2 tablet:ms-3">
            {APP_LOCALIZATION.PLACEHOLDERS.MISSING_DATA}
        </span>
    </Typography>
))
