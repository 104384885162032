import { useGetApiBlocksHierarchyQuery } from 'api/generator/appApi'
import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'

export function useHierarchyBaseInfoAndDocs() {
    const { data, isLoading, isError } = useGetApiBlocksHierarchyQuery()

    const hierarchyBaseInfoAndDocs = useMemo(() => {
        if (!data) return

        const hierarchyItemDocuments = data?.find(
            ({ itemName }) => itemName === 'Documents'
        )

        const baseInfo = data.filter((item) => item.parentId == null)
        const documents = data.filter(
            (item) => item.parentId === hierarchyItemDocuments?.id
        )

        return {
            baseInfo: sortBy(baseInfo, (item) => item.sortOrder),
            documents: sortBy(documents, (item) => item.sortOrder),
        }
    }, [data])

    return {
        isLoadingHierarchy: isLoading,
        isErrorHierarchy: isError,
        hierarchyBaseInfoAndDocs,
    }
}
