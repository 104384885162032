import { FC } from 'react'

import { AuthCheckProps } from './AuthCheck.types'
import { useGetApiUserQuery } from 'api/generator/appApi'
import { Preloader } from 'mmc-react-shared'
import { AxiosResponse } from 'axios'
import { APP_LOCALIZATION } from 'localization'

export const AuthCheck: FC<AuthCheckProps> = ({ children }) => {
    const { isLoading, isError, error } = useGetApiUserQuery()

    const serverError = error as AxiosResponse

    const errorRender = error
        ? APP_LOCALIZATION.ERRORS.RESPONSE[serverError.status]
        : undefined

    return (
        <Preloader
            isLoading={isLoading}
            isError={isError}
            error={errorRender}
            positionCenter
        >
            {children}
        </Preloader>
    )
}
