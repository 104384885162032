export const GENDER_LOCALIZATION = {
    Male: 'Мужской',
    Female: 'Женский',
}

export const IDENTITY_DOCUMENT_TYPES_LOCALIZATION = {
    InternationalPassport: 'Паспорт иностранного государства',
    TemporaryResidenceStateless: 'Разрешение на временное проживание ЛБГ',
    ResidencePermitStateless: 'Вид на жительство ЛБГ',
    RefugeeCard: 'Удостоверение беженца',
    RefugeeRecognitionCertificateRf:
        'Свидетельство о рассмотрении ходатайства о признании беженцем на территории РФ',
    TemporaryAsylumCertificateRf:
        'Свидетельство о предоставлении временного убежища на территории РФ',
    IdentificationCertificate: 'Справка об установлении личности',
    Other: 'Другое',
    NationalPassportUa: 'Национальный паспорт Украины',
    PassportRf: 'Паспорт гражданина РФ',
    BirthCertificate: 'Свидетельство о рождении',
    ForeignBirthCertificate: 'Свидетельство о рождении иностранного государства',
    BirthCertificateRf: 'Свидетельство о рождении РФ',
    IdentificationCertificateStateless:
        'Документ, удостоверяющий личность лица без гражданства',
    TravelDocumentNonResident: 'Проездной',
}

export const DMS_TYPES_LOCALIZATION = {
    Exists: 'имеется полис ДМС',
    InSystem: 'Оформление полиса ДМС в системе',
    OnForm: 'Оформление полиса ДМС на бланке',
}
