import { ForeignProfileContract } from 'api/generator/appApi'

export const USER = {
    userId: 'e90e1d72-eda9-4277-b0ac-53b33fdc5d2b',
    login: 'mmctestov',
    surname: 'ММЦ-Тестов',
    name: 'ММЦ-Тест',
    patronymic: 'ММЦ-Тестович',
    role: 'ARM_MCS_MAIN_OPERATOR',
    permissions: ['BasicView', 'ContactView', 'DocsView', 'PersonaView', 'LaborView'],
}

export const TEST_IDS = {
    HEADER__LOGO: 'HEADER__LOGO',
    HEADER__LOGOUT: 'HEADER__LOGOUT',
    CITIZEN_FIO: 'CITIZEN_FIO',
    ATTENTION: 'ATTENTION',
    INFO_FIELDS: 'INFO_FIELDS',
    PERSONAL_INFORMATION_VIEW_TABLET: 'PERSONAL_INFORMATION_VIEW_TABLET',
    PERSONAL_INFORMATION_VIEW_MOBILE: 'PERSONAL_INFORMATION_VIEW_MOBILE',
    PERSONAL_INFORMATION_PHOTO: 'PERSONAL_INFORMATION_PHOTO',
}

export const AUTH_ERROR = {
    response: {
        status: 401,
        headers: {
            location: '/auth-path',
        },
    },
    config: {
        url: '/path',
        method: 'get',
    },
}

export const SERVER_ERROR = {
    response: {
        status: 500,
        statusText: 'Server error',
    },
    config: {
        url: '/path',
        method: 'get',
    },
}

export const SERVER_SUCCESS = {
    response: {
        status: 200,
    },
    config: {
        url: '/path',
        method: 'get',
    },
}

export const DATE_VALID = '1955-12-12'

export const DATE_INVALID = '1955-02-31'

export const STYLE_OBJECT = { margin: '20px' }

export const PROFILE: ForeignProfileContract = {
    uuid: '04205501015705356452881',
    surname: 'Вайнгерт',
    name: 'Родимир',
    patronymic: 'Святославович',
    surnameLatin: null,
    nameLatin: 'Rodimir',
    patronymicLatin: null,
    birthdate: '1955-12-12',
    gender: 'Male',
    citizenship: {
        name: 'Австрия',
        code: 'AUT',
    },
    nationality: null,
    countryOfBirth: {
        name: 'Австрия',
        code: 'AUT',
    },
    placeOfBirth: 'Вена',
    registrationAddress: undefined, // с бэка может прийти null, что не соответствует контракту
    residenceAddress: undefined, // с бэка может прийти null, что не соответствует контракту
    addressesMatched: null,
    phoneNumber: '79093123121',
    additionalPhoneNumber: null,
    email: null,
    personalPhotoFile: {
        id: 12,
        previewId: null,
        fileType: 'Jpeg',
        fileSize: 196400,
    },
    isWanted: null,
    arrivalDocs: [
        {
            files: [
                {
                    id: 6,
                    previewId: 7,
                    fileType: 'Pdf',
                    fileSize: 5533194,
                },
            ],
            issuer: null,
            registrationDate: null,
            stayExpiresAt: null,
        },
    ],
    biometryFingerprintDocs: [],
    foreignCards: [
        {
            files: [],
            cardNumber: 'AA0003590',
            issueDate: '2017-07-19',
            expirationDate: '2022-07-19',
        },
    ],
    identityDocs: [
        {
            files: [
                {
                    id: 4,
                    previewId: 5,
                    fileType: 'Pdf',
                    fileSize: 2830,
                },
                {
                    id: 16,
                    previewId: null,
                    fileType: 'Jpeg',
                    fileSize: 71088,
                },
                {
                    id: 17,
                    previewId: null,
                    fileType: 'Jpeg',
                    fileSize: 56161,
                },
                {
                    id: 18,
                    previewId: null,
                    fileType: 'Docx',
                    fileSize: 56161,
                },
            ],
            identityDocumentType: 'InternationalPassport',
            series: null,
            number: '123122 122213',
            issuedAt: '2000-12-14',
            expirationDate: '2030-12-15',
            issuer: 'кем-то выдан',
            isRussianSource: false,
        },
    ],
    medicalDocs: [],
    migrationCards: [
        {
            files: [
                {
                    id: 8,
                    previewId: 9,
                    fileType: 'Pdf',
                    fileSize: 5533194,
                },
            ],
            series: '',
            number: '',
            startAt: '2024-04-01',
            arrivedAt: '2024-06-30',
            entryCheckpoint: null,
            visitGoal: null,
        },
    ],
    testingDocs: [
        {
            files: [
                {
                    id: 10,
                    previewId: 11,
                    fileType: 'Pdf',
                    fileSize: 1245897,
                },
            ],
            series: '123',
            number: '124123',
            issuedAt: '2024-06-30',
            issuer: null,
            docType: null,
        },
    ],
    innDocs: [],
    dmsDocs: [],
    residencePermitDocs: [],
    temporaryResidencePermitDocs: [],
    previousFioDocs: [],
    patentDocs: [],
    appeals: [],
}
