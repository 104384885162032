import { Routing } from 'routing/Routing'
import { AppInit } from 'containers/AppInit'

export const App = () => {
    return (
        <AppInit>
            <Routing />
        </AppInit>
    )
}

export default App
