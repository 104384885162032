import React, { FC } from 'react'
import cn from 'classnames'
import { Collapse as AntdCollapse } from 'antd'
import { CollapseProps } from './Collapse.types'
import { Icon, useBreakpoint } from 'mmc-react-shared'
import styles from './Collapse.module.css'

export const Collapse: FC<CollapseProps> = React.memo((props) => {
    const isTablet = useBreakpoint('Tablet')

    const className = cn(isTablet ? styles.tablet : styles.mobile, props.className)

    const expandIcon: CollapseProps['expandIcon'] = ({ isActive }) => (
        <Icon
            svgType={
                isActive ? 'SvgIconExpandLessRound12X7' : 'SvgIconExpandMoreRound12X7'
            }
            pathFill="text-custom-neutral-19"
            className="mx-3.5"
        />
    )

    return (
        <AntdCollapse
            {...props}
            className={cn(className, 'tablet:m-6 m-3')}
            rootClassName={cn('bg-transparent', styles.root)}
            expandIconPosition="end"
            expandIcon={expandIcon}
            bordered={false}
        />
    )
})
