import { Icon, Typography } from 'mmc-react-shared'
import React, { FC } from 'react'
import { HeaderProps } from './Header.types'

export const Header: FC<HeaderProps> = React.memo(({ title }) => {
    return (
        <Typography
            type="heading-6"
            weight="600"
            className="flex items-center mb-6 tablet:p-3"
        >
            <Icon
                svgType="SvgIconFileDocumentOutline16X20"
                pathFill="text-custom-neutral-15"
                iconContainerPreset="40"
                iconContainerBg="bg-custom-neutral-2"
                iconContainerClassName="mr-3 hidden tablet:flex rounded-s"
            />

            {title}
        </Typography>
    )
})
