export const CITIZEN_CARD_LOCALIZATION = {
    APPEALS_TITLE: 'Обращения',
    DOCUMENTS_TITLE: 'Документы',
    DOCUMENT_TITLE: 'Документ',
    PERSONAL_DATA_TITLE: 'Персональные данные',
    ADDRESS_AND_CONTACTS_TITLE: 'Адреса и контакты',
    WORK_DATA_TITLE: 'Трудовая деятельность',

    IDENTITY_DOCUMENT: 'Документ, удостоверяющий личность',
    MIGRATION_DOCUMENT: 'Миграционная карта',
    ARRIVAL_DOCUMENT: 'Уведомление о прибытии иностранного гражданина',
    MEDICAL_VERDICT: 'Медицинское заключение',
    HIV_CERTIFICATE: 'Сертификат об отсутствии ВИЧ-инфекции',
    PSYCHIATRRIST_NARCOLOGIST_VERDICT: 'Заключение врача психиатра-нарколога',
    PREVIOUS_FIO: 'Документ, подтверждающий факт изменения ФИО',
    BIOMETRY_FINGERPRINT:
        'Документ, подтверждающий прохождение обязательной дактилоскопической регистрации и фотографирования',
    INN_DOCUMENT: 'ИНН',
    DMS_DOCUMENT: 'Полис ДМС',
    RESIDENCE_PERMIT_DOCUMENT: 'Вид на жительство',
    FOREIGN_CARD_DOCUMENT: 'Электронная карта иностранного гражданина (ЭКИГ)',
    TESTING_DOCUMENT: 'Тестирование',
    TEMPORARY_RESIDENCE_PERMIT_DOCUMENT: 'Разрешение на временное проживание',

    DOCUMENT_NOT_EXIST: 'Документ отсутствует',
}
