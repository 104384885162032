import React, { FC } from 'react'
import { CitizenPersonalDataProps } from './CitizenPersonalData.types'
import { Col, Row } from 'antd'
import { GUTTER } from 'pages/CitizenCard/CitizenCard.consts'
import { CITIZEN_PERSONAL_DATA_LOCALIZATION } from './CitizenPersonalData.localization'
import { FieldView } from 'components/controls/FieldView'
import { InformationGroup } from '../InformationGroup'

export const CitizenPersonalData: FC<CitizenPersonalDataProps> = React.memo(
    ({
        surname,
        name,
        patronymic,
        surnameLatin,
        nameLatin,
        patronymicLatin,
        gender,
        birthdate,
        citizenship,
        countryOfBirth,
        placeOfBirth,
    }) => {
        return (
            <InformationGroup>
                <Row gutter={GUTTER}>
                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.LASTNAME_RUS}
                            value={surname}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.LASTNAME_ENG}
                            value={surnameLatin}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.FIRSTNAME_RUS}
                            value={name}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.FIRSTNAME_ENG}
                            value={nameLatin}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.MIDDLENAME_RUS}
                            value={patronymic}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.MIDDLENAME_ENG}
                            value={patronymicLatin}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.GENDER}
                            value={gender}
                        />
                    </Col>

                    <Col span={12} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.DATE_OF_BIRTH}
                            value={birthdate}
                        />
                    </Col>

                    <Col span={24} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.CITIZENSHIP}
                            value={citizenship}
                        />
                    </Col>

                    <Col span={24} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.COUNTRY_OF_BIRTH}
                            value={countryOfBirth}
                        />
                    </Col>

                    <Col span={24} md={6}>
                        <FieldView
                            label={CITIZEN_PERSONAL_DATA_LOCALIZATION.PLACE_OF_BIRTH}
                            value={placeOfBirth}
                        />
                    </Col>
                </Row>
            </InformationGroup>
        )
    }
)
