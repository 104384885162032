import React, { FC } from 'react'
import { Header } from '../Header'
import { MIGRATION_CARD_LOCALIZATION } from './MigrationCard.localization'
import { Col, Row } from 'antd'
import { FieldView } from 'components/controls/FieldView'
import { useGetProfile } from 'hooks/useGetProfile'
import { formatDate } from 'mmc-react-shared'
import { getSeriesAndNumber } from 'utils/common.utils'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocuments/CitizenCardDocuments.consts'
import { DocumentView } from 'components/controls/DocumentView'

export const MigrationCard: FC = React.memo(() => {
    const { profile } = useGetProfile()

    const document = profile?.migrationCards?.[0] || {}

    return (
        <>
            <Header title={MIGRATION_CARD_LOCALIZATION.TITLE} />

            <Row {...CITIZEN_DOC_GRID_ROW}>
                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={MIGRATION_CARD_LOCALIZATION.SERIES_AND_NUMBER}
                        value={getSeriesAndNumber(document)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={MIGRATION_CARD_LOCALIZATION.START_AT_DATE}
                        value={formatDate(document.startAt)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={MIGRATION_CARD_LOCALIZATION.ARRIVED_AT_DATE}
                        value={formatDate(document.arrivedAt)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={MIGRATION_CARD_LOCALIZATION.VISIT_GOAL}
                        value={document.visitGoal}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={MIGRATION_CARD_LOCALIZATION.ENTRY_CHECKPOINT}
                        value={document.entryCheckpoint}
                    />
                </Col>

                <Col xs={24}>
                    <DocumentView files={document.files} />
                </Col>
            </Row>
        </>
    )
})
