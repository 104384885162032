import React, { FC } from 'react'
import { CitizenDocumentsProps, ShortDocumentInfo } from './CitizenDocuments.types'
import { FieldView } from 'components/controls/FieldView'
import { useNavigator } from 'hooks/useNavigator'
import { useParams } from 'react-router-dom'
import { CitizenCardUrlParams } from 'types/business.types'
import { CITIZEN_CARD_LOCALIZATION } from 'pages/CitizenCard/CitizenCard.localization'
import { CollapseItem } from 'components/business/CollapseItem'
import { useScrollTo } from 'hooks/useScrollTo'
import styles from './CitizenDocuments.module.css'
import classNames from 'classnames'

export const CitizenDocuments: FC<CitizenDocumentsProps> = React.memo(({ documents }) => {
    const { uidOrCard } = useParams<CitizenCardUrlParams>()

    const { goToCitizenCardDocument } = useNavigator()

    const dataScrollAttr = 'document-type'

    useScrollTo(dataScrollAttr)

    const renderDocument = (document: ShortDocumentInfo) => {
        if (!document.type) return

        const handleClick = () => {
            if (!document.value) return

            goToCitizenCardDocument(uidOrCard, document.type)
        }

        return (
            <CollapseItem
                key={document.type}
                onClick={handleClick}
                dataScrollAttrKey={dataScrollAttr}
                dataScrollAttrValue={document.type}
                isActive={!!document.value}
            >
                <FieldView
                    label={document.name}
                    value={document.value || CITIZEN_CARD_LOCALIZATION.DOCUMENT_NOT_EXIST}
                    className={classNames(styles.fieldView, '!mb-0')}
                />
            </CollapseItem>
        )
    }

    return documents.map(renderDocument)
})
