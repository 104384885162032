import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, usePermissions } from 'mmc-react-shared'
import { useNavigator } from 'hooks/useNavigator'

import { HOME_LOCALIZATION } from './Home.localization'
import { LayoutOneColumn } from 'components/layouts/LayoutOneColumn'
import ScannerIcon from './scanner.svg?react'
import { APP_LOCALIZATION } from 'localization'

export const Home: FC = React.memo(() => {
    const { goToSearchByCard, goToSearchByQR } = useNavigator()

    const cameraPermissionState = usePermissions('camera' as PermissionName)

    const [hasCameraPermission, setCameraPermission] = useState<boolean | undefined>()

    useEffect(() => {
        let stream: MediaStream
        const checkCameraPermission = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia({
                    video: true,
                    preferCurrentTab: true,
                })

                setCameraPermission(true)
            } catch (err) {
                setCameraPermission(false)
            }
        }

        setCameraPermission(cameraPermissionState === 'granted')

        if (cameraPermissionState === 'prompt') checkCameraPermission()

        return () => {
            const tracks = stream?.getTracks()

            if (tracks) {
                tracks.forEach((track) => track.stop())
            }
        }
    }, [cameraPermissionState])

    return (
        <LayoutOneColumn>
            <div className="flex flex-col justify-between h-full">
                <div className="flex justify-center">
                    {hasCameraPermission ? (
                        <Alert
                            message={HOME_LOCALIZATION.TITLE}
                            inlineFlex
                            className="text-center"
                            closable={false}
                        />
                    ) : (
                        <Alert
                            message={HOME_LOCALIZATION.CAMERA_ACCESS_DENIED_MESSAGE}
                            type="error"
                            withBorder
                            inlineFlex
                        />
                    )}
                </div>

                <div className="flex justify-center relative">
                    <ScannerIcon className="w-[160px] md:w-auto" />

                    <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center">
                        <Button
                            disabled={!hasCameraPermission}
                            onClick={goToSearchByQR}
                            type="dark"
                            className="min-w-[224px]"
                        >
                            {APP_LOCALIZATION.ACTIONS.SEARCH_BY_QR}
                        </Button>
                    </div>
                </div>

                <div className="flex justify-center">
                    <Button onClick={goToSearchByCard} type="dark-outline">
                        {HOME_LOCALIZATION.SEARCH_BY_CARD}
                    </Button>
                </div>
            </div>
        </LayoutOneColumn>
    )
})
