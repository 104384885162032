import React from 'react'
import { INFO_FIELDS_LOCALIZATION } from './InfoFields.localization'
import { InfoFieldsProps } from './InfoFields.types'
import { FieldView } from 'components/controls/FieldView'
import { dayjsDateIsValidStrict, formatDate } from 'mmc-react-shared'
import {
    getIssuedAt,
    getIssuer,
    getLatestDoc,
    getSeriesAndNumber,
    joinTrue,
} from 'utils/common.utils'
import { APP_LOCALIZATION } from 'localization'
import { TEST_IDS } from 'consts/tests.consts'

export const InfoFields: React.FC<InfoFieldsProps> = React.memo(
    ({
        citizenship,
        birthdate,
        patentDocs,
        testingDocs,
        biometryFingerprintDocs,
        identityDocs,
        gender,
        className,
        componentRef,
        style,
        dataTestId = TEST_IDS.INFO_FIELDS,
    }) => {
        const identityDocLatest = getLatestDoc(identityDocs)

        const biometryFingerprintDocLatest = getLatestDoc(biometryFingerprintDocs)

        const testingDocLatest = getLatestDoc(testingDocs)

        const patentDocLatest = getLatestDoc(patentDocs)

        const identityDocValue = getSeriesAndNumber(identityDocLatest)

        const biometryFingerprintDocValue = joinTrue([
            getSeriesAndNumber(biometryFingerprintDocLatest),
            getIssuedAt(biometryFingerprintDocLatest),
        ])

        const testingDocValue = joinTrue([
            getSeriesAndNumber(testingDocLatest),
            joinTrue([getIssuedAt(testingDocLatest), getIssuer(testingDocLatest)], ', '),
        ])

        const patentDocsValue = joinTrue([
            getSeriesAndNumber(patentDocLatest),
            getIssuedAt(patentDocLatest),
        ])

        return (
            <div
                className={className}
                ref={componentRef}
                style={style}
                data-testid={dataTestId}
            >
                <div className="grid grid-cols-2 tabletL:grid-cols-4 gap-4 mb-4">
                    <FieldView
                        label={INFO_FIELDS_LOCALIZATION.CITIZENSHIP}
                        value={citizenship}
                    />

                    <FieldView
                        label={INFO_FIELDS_LOCALIZATION.IDENTITY_DOC}
                        value={identityDocValue}
                    />

                    <FieldView
                        label={INFO_FIELDS_LOCALIZATION.DATE_OF_BIRTH}
                        value={
                            dayjsDateIsValidStrict(birthdate)
                                ? formatDate(birthdate)
                                : undefined
                        }
                    />

                    <FieldView
                        label={INFO_FIELDS_LOCALIZATION.GENDER}
                        value={
                            gender
                                ? APP_LOCALIZATION.DICTIONARIES.GENDER[gender]
                                : undefined
                        }
                    />
                </div>

                <div className="grid grid-cols-2 tabletL:grid-cols-2 gap-4 mb-4">
                    <FieldView
                        label={INFO_FIELDS_LOCALIZATION.PATENT}
                        value={patentDocsValue}
                    />

                    <FieldView
                        label={INFO_FIELDS_LOCALIZATION.FINGERPRINT}
                        value={biometryFingerprintDocValue}
                    />
                </div>

                <FieldView
                    label={INFO_FIELDS_LOCALIZATION.TESTING}
                    value={testingDocValue}
                />
            </div>
        )
    }
)
