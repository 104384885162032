import { memo, useCallback, useMemo, useState } from 'react'

import { Label, useBlockBodyScroll, useElementVisible } from 'mmc-react-shared'

import { FieldView } from '../FieldView'

import { DocumentPreview } from './components/DocumentPreview'

import { DocumentViewProps } from './DocumentView.types'
import { DOCUMENT_VIEW_LOCALIZATION } from './DocumentView.localization'
import { DocumentFullscreen } from 'components/common/DocumentFullscreen'

export const DocumentView: React.FC<DocumentViewProps> = memo(
    ({ files, label = DOCUMENT_VIEW_LOCALIZATION.SCANNED_DOCUMENT }) => {
        const [currentFileIndex, setCurrentFileIndex] = useState(0)

        const filesLength = files?.length

        const availableFiles = useMemo(
            () => files?.filter(({ fileType }) => fileType !== 'Unknown'),
            [files]
        )

        const {
            elementVisible: isFullscreenOpened,
            handleHideElement: handleCloseFullscreen,
            handleShowElement: handleOpenFullscreen,
        } = useElementVisible()

        const openFullscreen = useCallback(
            (fileIndex: number) => {
                handleOpenFullscreen()
                setCurrentFileIndex(fileIndex)
            },
            [handleOpenFullscreen]
        )

        const nextFullscreen = useCallback(() => {
            if (!filesLength) return

            setCurrentFileIndex((currentFileIndex) =>
                currentFileIndex === filesLength - 1 ? 0 : currentFileIndex + 1
            )
        }, [filesLength])

        const prevFullscreen = useCallback(() => {
            if (!filesLength) return

            setCurrentFileIndex((currentFileIndex) =>
                currentFileIndex === 0 ? filesLength - 1 : currentFileIndex - 1
            )
        }, [filesLength])

        useBlockBodyScroll(isFullscreenOpened)

        if (!availableFiles?.length) {
            return (
                <FieldView
                    label={label}
                    value={DOCUMENT_VIEW_LOCALIZATION.DOCUMENT_FILE_NOT_EXIST}
                />
            )
        }

        return (
            <div>
                <Label text={label} textColor="text-custom-neutral-13" />

                <div className="flex flex-wrap align-middle gap-3 pt-2">
                    {availableFiles.map((file, fileIndex) => (
                        <DocumentPreview
                            key={file.id}
                            file={file}
                            openFullscreen={openFullscreen}
                            fileIndex={fileIndex}
                        />
                    ))}

                    {isFullscreenOpened && (
                        <DocumentFullscreen
                            currentFileIndex={currentFileIndex}
                            files={availableFiles}
                            prevFullscreen={prevFullscreen}
                            nextFullscreen={nextFullscreen}
                            onClose={handleCloseFullscreen}
                        />
                    )}
                </div>
            </div>
        )
    }
)
