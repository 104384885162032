import { Button, Layout, Typography } from 'mmc-react-shared'
import { FC } from 'react'
import { OFFLINE_LOCALIZATION } from './Offline.localization'
import { OfflineProps } from './Offline.types'
import styles from './Offline.module.css'

export const Offline: FC<OfflineProps> = ({ updateConnectionStatus }) => (
    <Layout
        className={styles.root}
        mainClassName="h-full justify-center bg-white row-span-full"
    >
        <div className="flex flex-col items-center justify-center gap-y-12 p-12">
            <Typography
                type="heading-4"
                text={OFFLINE_LOCALIZATION.TITLE}
                textColor="text-custom-neutral-19"
                className="text-center"
            />

            <Typography
                text={OFFLINE_LOCALIZATION.CONTENT}
                textColor="text-custom-neutral-19"
                className="text-center"
            />

            <Button
                type="dark"
                className="w-full tablet:w-auto"
                onClick={updateConnectionStatus}
            >
                {OFFLINE_LOCALIZATION.CHECK_CONNECTION}
            </Button>
        </div>
    </Layout>
)
